import React from "react";
import {Row, Col, Label, Table, Button, ModalFooter,Form, FormGroup, Input, InputGroup, Modal, ModalHeader, ModalBody} from 'reactstrap';
import { db, functions } from '../components/firebase/firebase';
import { observer, inject } from 'mobx-react';
import Warning from '../assets/images/pp_warning.svg';
import { QRCodeSVG } from 'qrcode.react';
import * as XLSX from "xlsx";

@inject('observableStore')
@observer

class Barren extends React.Component {
	render() {
		return (
			<div style={{paddingBottom: 100, paddingLeft: 10, paddingRight: 10}}>		
				<Row>
					<Col lg={12}>
						<h2 className="font-light text-muted topTitle" style={{fontSize: 28, fontWeight: 900, color: '#212529'}}>Opwaardeer links</h2>
					</Col>
				</Row>	
				<Row style={{marginTop: 30, paddingLeft: 15, paddingRight: 15, }}>	
					<Col className="graph-display graph-menu graph-scroll" style={{marginRight: 20, padding: 40, paddingTop: 30}}>					
						<div style={{minHeight: 450}}>
							{!this.state.loaded ?
							<div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 100}}>
								<div class="loading-spinner" style={{width: 45, height: 45, borderWidth: 4}}></div>
								<p style={{marginTop: 60}}>Links ophalen...</p>
							</div>
							:
							<div>
								<Table className="v-middle" responsive style={{marginBottom: 0, borderWidth: 0}}>
									<thead>
										<tr>
											<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Select</td>
											<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Qrcode</td>
											<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Link</td>
											<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Muntjes</td>
											<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Notitie</td>
											<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Geldig</td>
										</tr>
									</thead>
									<tbody>
									{this.state.promoties && this.state.promoties.length > 0 ? this.state.promoties.map((promo, index) => (							
										<tr style={{background: index%2 === 0 ? null : 'rgba(0,0,0,0.03)'}}>
											<td style={{padding: 20}}>
												<div onClick={() => this.selectPromo(promo.code)} style={{cursor: 'pointer'}}>
													{this.state.selectedCodes.includes(promo.code) ?
													<div style={{width: 20, height: 20, borderWidth: 2, borderStyle: 'solid', borderColor: '#4cc15f', borderRadius: 4, marginRight: 10}}>
														<i class="fa fa-check" style={{color: '#4cc15f', paddingLeft: 1, marginTop: 2, position: 'absolute'}}></i>
													</div>
													:
													<div style={{width: 20, height: 20, borderWidth: 2, borderStyle: 'solid', borderColor: 'rgba(0,0,0,0.2)', borderRadius: 4, marginRight: 10}}></div>
													}
												</div>
											</td>
											<td style={{padding: 20,}}><QRCodeSVG value={promo.url} size={90} /></td>
											<td style={{padding: 20, wordBreak: 'break-all', maxWidth: 350, minWidth: 250}}><h6 style={{fontSize: 14, fontWeight: 500}}>{promo.url}</h6></td>
											<td style={{padding: 20, textAlign: 'center', fontSize: 18}}>
												<h6 style={{fontWeight: 500, display: 'inline', background: '#43A047', textAlign: 'center', borderRadius: 5, color: '#fff', padding: '7px 15px'}}>{promo.tokens}</h6>
											</td>	
											<td style={{padding: 20,}}><h6 style={{fontSize: 14, fontWeight: 500}}>{promo.note}</h6></td>											
											<td style={{padding: 20,}}><h6 style={{fontSize: 14, fontWeight: 500}}>{promo.multiUser ? 'Eenmalig per bezoeker' : 'Eenmalig geldig'}</h6></td>	
										</tr>
									))
									:
									<div className="emptyContainer" style={{position: 'absolute', borderWidth: 0, minHeight: 450, flexDirection: 'column', justifyContent: 'center'}}>
										<img src={Warning} style={{width: 50, marginBottom: 15, marginTop: -30}} />
										<span>Er zijn nog geen opwaardeer links toegevoegd</span>
									</div>
									}
									</tbody>
								</Table>
								{this.state.promoties && this.state.promoties.length > 0 ?
								<div style={{widht: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 50, marginBottom: 20}}>
									<div className="addBtnTop" style={{marginRight: 15, width: 190, marginBottom: 0, background: '#d12a5e', color: '#fff'}} onClick={() => this.getPromoties()}>Laad meer</div>
								</div>
								:null}
							</div>
							}
						</div>					
					</Col>
					<Col lg={2} className="graph-display" style={{background: 'none', padding: 30, minWidth: 240}}>
						<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 14, marginBottom: 20, cursor: 'pointer', backgroundColor:'rgb(209, 42, 94)', color: '#fff', padding: 10, borderRadius: 7}} onClick={() => this.togglePromoModal()}>
							<i class="fa fa-link" style={{marginRight: 10, color: '#fff'}}></i>
							<span>Link aanmaken</span>
						</div>
						<div style={{width: '100%', backgroundColor: 'rgba(0,0,0,0.1)', height: 1, marginBottom: 20}}></div>
						<div style={{display: 'flex', alignItems: 'center', fontSize: 14, marginBottom: 20, cursor: 'pointer'}} onClick={() => this.copyMultiple()}>
							<i class="fa fa-copy" style={{marginRight: 10}}></i>
							<span>Selectie kopiëren</span>
						</div>
						<div style={{display: 'flex', alignItems: 'center', fontSize: 14, marginBottom: 20, cursor: 'pointer'}} onClick={() => this.deleteMultiple()}>
							<i class="fa fa-trash" style={{marginRight: 10}}></i>
							<span>Selectie verwijderen</span>
						</div>
						<div style={{display: 'flex', alignItems: 'center', fontSize: 14, marginBottom: 20, cursor: 'pointer'}} onClick={() => this.exportAll()}>
							<i class="fa fa-file-export" style={{marginRight: 10}}></i>
							<span>Lijst exporteren</span>
						</div>
						<div style={{width: '100%', backgroundColor: 'rgba(0,0,0,0.1)', height: 1, marginBottom: 20}}></div>
						<div style={{display: 'flex', alignItems: 'center', fontSize: 14, marginBottom: 20, cursor: 'pointer'}} onClick={() => this.uploadExcel('mails')}>
							<i class="fa fa-envelope" style={{marginRight: 10}}></i>
							<span>Bulk e-mails</span>
						</div>
						<div style={{display: 'flex', alignItems: 'center', fontSize: 14, marginBottom: 20, cursor: 'pointer'}} onClick={() => this.uploadExcel('cardtopup')}>
							<i class="fa fa-ring" style={{marginRight: 10}}></i>
							<span>Bandjes opwaarderen</span>
						</div>
						<div style={{display: 'flex', alignItems: 'center', fontSize: 14, marginBottom: 20, cursor: 'pointer'}} onClick={() => this.uploadExcel('cardread')}>
							<i class="fa fa-barcode-read" style={{marginRight: 10}}></i>
							<span>Bandjes uitlezen</span>
						</div>
						<input type="file" name="upadlocsv" id="upadlocsv" style={{display: 'none'}} ref={(ref) => this.upload = ref} onChange={this.readExcel.bind(this)}/>
					</Col>
				</Row>	
				<Modal isOpen={this.state.promoModal} toggle={this.togglePromoModal} className={this.props.className} centered >
					<ModalHeader toggle={this.togglePromoModal}>Opwaardeer links toevoegen</ModalHeader>
					{this.state.addPromoLoad ?
					<ModalBody>
						<div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 20}}>
							<div class="loading-spinner" style={{width: 45, height: 45, borderWidth: 4}}></div>
							<p style={{marginTop: 30}}>Opwaardeer link(s) aanmaken...</p>
						</div>
					</ModalBody>
					:
					<ModalBody>
						<Form className="mt-1">
							<label htmlFor="staticEmail" className="col-form-label" style={{marginBottom: 25}}>Genereer een opwaardeer link waarmee de ontvanger eenmalig kostenloos muntjes kan toevoegen aan zijn Partypay account.</label>
							<Row style={{marginBottom: 10}}>
								<Col sm={8}>
									<FormGroup>
										<Label htmlFor="example-email" style={{marginBottom: 10}}>Notitie</Label>
										<InputGroup>
											<Input type="text" style={{borderColor: this.state.reqFields.includes('promoNote') ? 'red' : undefined}} placeholder="geen notitie"  value={this.state.promoNote} onChange={e => this.setState({promoNote: e.target.value})}/>
										</InputGroup>
									</FormGroup>
								</Col>
								<Col sm={4}>
									<Label htmlFor="example-email" style={{marginBottom: 10}}>Eigen gebruik</Label>
									<Input type="select" className='minimal' style={{height: 40, paddingBottom: 10, paddingTop: 10, paddingRight: 10, borderColor: 'rgb(217, 217, 217)', borderRadius: 7}} onChange={e => this.setState({promoPaid: e.target.value})}>
										<option value={false} selected={!this.state.promoPaid}>Ja</option>
										<option value={true} selected={this.state.promoPaid}>Nee</option>
									</Input>	
								</Col>
							</Row>
							<Row>
								<Col sm={3}>
									<FormGroup>
										<Label htmlFor="example-email" style={{marginBottom: 10}}>Muntjes</Label>
										<InputGroup>
											<Input type="number" placeholder="selecteer aantal" style={{width: '100%', height: 38}} value={this.state.tokenAantal} onChange={e => this.setState({tokenAantal: e.target.value})}/>
										</InputGroup>
									</FormGroup>
								</Col>
								<Col sm={3}>
									<FormGroup>
										<Label htmlFor="example-email" style={{marginBottom: 10}}>Aantal links</Label>
										<InputGroup>
											<Input type="number" placeholder="selecteer aantal" style={{width: '100%', height: 38}} value={this.state.promoAantal} onChange={e => this.setState({promoAantal: parseFloat(e.target.value), promoAction: ''})}/>
										</InputGroup>
									</FormGroup>
								</Col>
								<Col sm={6}>
									<Label htmlFor="example-email" style={{marginBottom: 10}}>Actie</Label>
									<Input type="select" className='minimal' style={{height: 40, paddingBottom: 10, paddingTop: 10, paddingRight: 10, borderColor: 'rgb(217, 217, 217)', borderRadius: 7}} onChange={e => this.setState({promoAction: e.target.value})}>
										<option value={''} selected={this.state.promoAction === ''}>Geen actie</option>
										{this.state.promoAantal === 1 ?<option value={'mail'} selected={this.state.promoAction === 'mail'}>Mail versturen</option>:null}
										{this.state.promoAantal === 1 ?<option value={'whatsapp'} selected={this.state.promoAction === 'whatsapp'}>Delen via whatsapp</option>:null}
										<option value={'csv'} selected={this.state.promoAction === 'csv'}>CSV exporteren</option>
									</Input>	
								</Col>
							</Row>
							<Row onClick={() => this.setState({promoMultiUser: !this.state.promoMultiUser})} style={{cursor: 'pointer', marginTop: 25, marginBottom: 7, marginLeft: 1}}>
								{this.state.promoMultiUser ?
								<div style={{width: 20, height: 20, borderWidth: 2, borderStyle: 'solid', borderColor: '#4cc15f', borderRadius: 4, marginRight: 10}}>
									<i class="fa fa-check" style={{color: '#4cc15f', paddingLeft: 1, marginTop: 2, position: 'absolute'}}></i>
								</div>
								:
								<div style={{width: 20, height: 20, borderWidth: 2, borderStyle: 'solid', borderColor: 'rgba(0,0,0,0.2)', borderRadius: 4, marginRight: 10}}></div>
								}
								<p style={{fontSize: 13, marginTop: 2, color: '#4a4a4a'}}>Link mag door <span style={{color: '#d12a5e'}}>meerdere</span> bezoekers eenmalig worden gebruikt.</p>
							</Row>
							{this.state.promoAction === 'mail' && this.state.promoAantal === 1 ? 						
							<Row style={{marginBottom: 10}}>
								<Col sm={12}>
									<FormGroup>
										<Label htmlFor="example-email" style={{marginBottom: 10}}>E-mailadres</Label>
										<InputGroup>
											<Input type="text" style={{borderColor: this.state.reqFields.includes('promoMail') ? 'red' : undefined}} placeholder="email@email.com"  value={this.state.promoMail} onChange={e => this.setState({promoMail: e.target.value})}/>
										</InputGroup>
									</FormGroup>
								</Col>
							</Row>
							:null}
						</Form>
					</ModalBody>
					}
					<ModalFooter>
						<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={this.makePromocode}>
							{this.state.promoMail !== '' ? 'Toevoegen & verzenden' : 'Toevoegen'}
						</Button>
						<Button className="cancel_btn" onClick={this.togglePromoModal}>
							Sluit venster
						</Button>
					</ModalFooter>
				</Modal>
				<Modal isOpen={this.state.mailListModal} toggle={this.toggleMailListModal} className={this.props.className} centered >
					<ModalHeader toggle={this.toggleMailListModal}>Opwaardeer links mailen</ModalHeader>
					{this.state.mailPromoLoad ?
					<ModalBody>
						<div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 20}}>
							<div class="loading-spinner" style={{width: 45, height: 45, borderWidth: 4}}></div>
							<p style={{marginTop: 30}}>Opwaardeer link(s) mailen...</p>
						</div>
					</ModalBody>
					:
					<ModalBody>
						<Form className="mt-1">
							<label htmlFor="staticEmail" className="col-form-label" style={{marginBottom: 15}}>De onderstaande mail adressen ontvangen een mail waarmee er eenmalig kostenloos de aangegeven hoeveelheid muntjes kan worden opgewaardeerd</label>
							<Row style={{marginBottom: 10}}>
								<Col sm={8}>
									<FormGroup>
										<Label htmlFor="example-email" style={{marginBottom: 10}}>Notitie</Label>
										<InputGroup>
											<Input type="text" style={{borderColor: this.state.reqFields.includes('mailListNote') ? 'red' : undefined}} placeholder="geen notitie"  value={this.state.mailListNote} onChange={e => this.setState({mailListNote: e.target.value})}/>
										</InputGroup>
									</FormGroup>
								</Col>
							</Row>
							<Row style={{marginBottom: 10}}>
								<Col sm={12}>
									<div style={{maxHeight: 285, overflow: 'scroll'}}>
										<Label htmlFor="example-email" style={{marginBottom: 10}}>E-maillijst</Label>
										<Table className="v-middle" responsive style={{marginBottom: 0, borderWidth: 0}}>
											<thead>
												<tr>
													<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>E-mail</td>
													<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Munten</td>
													<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Acties</td>
												</tr>
											</thead>
											<tbody>
											{this.state.maillist.map((item, index) => (							
												<tr style={{background: index%2 === 0 ? null : 'rgba(0,0,0,0.03)'}}>
													<td style={{padding: 20, width: '80%'}}><h6 style={{fontSize: 14, fontWeight: 500}}>{item.mail}</h6></td>
													<td style={{padding: 20, width: '80%'}}><h6 style={{fontSize: 14, fontWeight: 500}}>{item.tokens}</h6></td>									
													<td style={{width: '20%', minWidth: 70, fontSize: 18, padding: 0, textAlign: 'right'}}>
														<i class="fa fa-trash hightlightIcon2" onClick={() => this.delMail(index)} style={{cursor:'pointer', marginRight: 30}}></i>
													</td>	
												</tr>
											))}
											</tbody>
										</Table>
									</div>
								</Col>
							</Row>
						</Form>
					</ModalBody>
					}
					<ModalFooter>
						<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={this.sendMailListPromo}>
							Verzenden
						</Button>
						<Button className="cancel_btn" onClick={this.toggleMailListModal}>
							Sluit venster
						</Button>
					</ModalFooter>
				</Modal>

				<Modal isOpen={this.state.cardTopupModal} toggle={this.toggleCardTopupModal} className={this.props.className} centered >
					<ModalHeader toggle={this.toggleCardTopupModal}>Bandjes/Pasjes opwaarderen</ModalHeader>
					{this.state.topupCardLoad ?
					<ModalBody>
						<div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 20}}>
							<div class="loading-spinner" style={{width: 45, height: 45, borderWidth: 4}}></div>
							<p style={{marginTop: 30}}>Bandjes/Pasjes opwaarderen...</p>
						</div>
					</ModalBody>
					:
					<ModalBody>
						<Form className="mt-1">
							<label htmlFor="staticEmail" className="col-form-label" style={{marginBottom: 15}}>De onderstaande bandjes/pasjes worden opgewaardeerd met de aangegeven hoeveelheid muntjes.</label>
							<Row style={{marginBottom: 10}}>
								<Col sm={12}>
									<div style={{maxHeight: 285, overflow: 'scroll'}}>
										<Label htmlFor="example-email" style={{marginBottom: 10}}>Bandjes/pasjes lijst</Label>
										<Table className="v-middle" responsive style={{marginBottom: 0, borderWidth: 0}}>
											<thead>
												<tr>
													<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>ID</td>
													<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Munten</td>
													<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Acties</td>
												</tr>
											</thead>
											<tbody>
											{this.state.cardlist.map((item, index) => (							
												<tr style={{background: index%2 === 0 ? null : 'rgba(0,0,0,0.03)'}}>
													<td style={{padding: 20, width: '80%'}}><h6 style={{fontSize: 14, fontWeight: 500}}>{item.cardID}</h6></td>
													<td style={{padding: 20, width: '80%'}}><h6 style={{fontSize: 14, fontWeight: 500}}>{item.tokens}</h6></td>									
													<td style={{width: '20%', minWidth: 70, fontSize: 18, padding: 0, textAlign: 'right'}}>
														<i class="fa fa-trash hightlightIcon2" onClick={() => this.delCard(index)} style={{cursor:'pointer', marginRight: 30}}></i>
													</td>	
												</tr>
											))}
											</tbody>
										</Table>
									</div>
								</Col>
							</Row>
						</Form>
					</ModalBody>
					}
					<ModalFooter>
						<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={() => this.setState({topupCardLoad: true},()=>this.topupCards())}>
							Opwaarderen
						</Button>
						<Button className="cancel_btn" onClick={this.toggleCardTopupModal}>
							Sluit venster
						</Button>
					</ModalFooter>
				</Modal>

				<Modal isOpen={this.state.readCardModal} toggle={this.togglereadCardModal} className={this.props.className} centered >
					<ModalHeader toggle={this.togglereadCardModal}>Bandjes/Pasjes uitlezen</ModalHeader>
					{this.state.readCardLoad ?
					<ModalBody>
						<div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 20}}>
							<div class="loading-spinner" style={{width: 45, height: 45, borderWidth: 4}}></div>
							<p style={{marginTop: 30}}>Saldo's uitlezen...</p>
						</div>
					</ModalBody>
					:
					<ModalBody>
						<p style={{fontSize: 13, marginTop: 10, color: '#4a4a4a'}}>De bandjes/pasjes zijn uitgelezen. Hieronder de totaal data van alle bandjes/pasjes uit de aangeleverde lijst.</p>
						<div style={{width: '100%', backgroundColor: 'rgba(0,0,0,0.1)', height: 1, marginTop: 30, marginBottom: 30}}></div>
						<div style={{display: 'flex'}}>
							<div style={{width: 300}}><p style={{fontSize: 13, color: '#4a4a4a'}}>Gevonden gebruikers:</p></div>
							<div style={{width: 50}}><p style={{fontSize: 13, color: 'rgb(74, 74, 74,0.8)'}}>{this.state.readCardTotal.users}</p></div>
						</div>
						<div style={{display: 'flex'}}>
							<div style={{width: 300}}><p style={{fontSize: 13, color: '#4a4a4a'}}>Overgebleven munten:</p></div>
							<div style={{width: 50}}><p style={{fontSize: 13, color: 'rgb(74, 74, 74,0.8)'}}>{this.state.readCardTotal.tokens}</p></div>
						</div>
						<div style={{display: 'flex'}}>
							<div style={{width: 300}}><p style={{fontSize: 13, color: '#4a4a4a'}}>Overgebleven relatie/sponsor munten:</p></div>
							<div style={{width: 50}}><p style={{fontSize: 13, color: 'rgb(74, 74, 74,0.8)'}}>{this.state.readCardTotal.promotokens}</p></div>
						</div>
						<div style={{width: '100%', backgroundColor: 'rgba(0,0,0,0.1)', height: 1, marginTop: 30, marginBottom: 30}}></div>
						<div style={{display: 'flex'}}>
							<div style={{width: 300}}><p style={{fontSize: 13, color: '#4a4a4a'}}>Gemiddeld over:</p></div>
							<div style={{width: 50}}><p style={{fontSize: 13, color: 'rgb(74, 74, 74,0.8)'}}>{(this.state.readCardTotal.tokens / this.state.readCardTotal.users) ? Math.trunc(this.state.readCardTotal.tokens / this.state.readCardTotal.users) : 0}</p></div>
						</div>
						<div style={{display: 'flex'}}>
							<div style={{width: 300}}><p style={{fontSize: 13, color: '#4a4a4a'}}>Gemiddeld over (relatie/sponsor):</p></div>
							<div style={{width: 50}}><p style={{fontSize: 13, color: 'rgb(74, 74, 74,0.8)'}}>{(this.state.readCardTotal.promotokens / this.state.readCardTotal.users) ?  Math.trunc(this.state.readCardTotal.promotokens / this.state.readCardTotal.users) : 0}</p></div>
						</div>
					</ModalBody>
					}
					<ModalFooter>
						<Button className="cancel_btn" onClick={this.togglereadCardModal}>Sluit venster</Button>
					</ModalFooter>
				</Modal>

			</div>
		);
	}
	
	state = {
		promoties: [],
		promoModal: false,
		infoTokens: '',
		infoIndex: 0,
		reqFields: [],
		selectedCodes: [],
		loaded: false,
		addPromoLoad: false,

		mailListModal: false,
		mailPromoLoad: false,
		maillist: [],
		mailListAantal: 0,
		mailListNote: '',

		cardTopupModal: false,
		topupCardLoad: false,
		cardlist: [],

		readCardModal: false,
		readCardLoad: false,
		readCardTotal: {users: 0, tokens: 0, promotokens: 0}
	}	

	componentDidMount(){if(this.props.observableStore.currEvent.ID){this.getPromoties()}}

	togglePromoModal = () => {this.setState({
		promoModal: !this.state.promoModal,
		promoNote: '',
		promoAantal: 1,
		tokenAantal: 0,
		promoAction: '',
		promoPaid: false,
		promoMail: '',
		promoMultiUser: false,
		reqFields: [],
		addPromoLoad: false
	})}

	toggleMailListModal = () => {this.setState({mailListModal: !this.state.mailListModal})}
	toggleCardTopupModal = () => {this.setState({cardTopupModal: !this.state.cardTopupModal, topupCardLoad: false})}
	togglereadCardModal = () => {this.setState({readCardModal: !this.state.readCardModal, readCardTotal: {users: 0, readCardLoad: false, tokens: 0, promotokens: 0}})}

	makePromocode = () => {
		if(this.reqFields()){
			if(this.state.tokenAantal > 0){
				this.setState({addPromoLoad: true});
				var promoUrls = [];
				for(let i = 0; i < this.state.promoAantal; i++){
					var eref = db.collection("events").doc(this.props.observableStore.currEvent.ID).collection("tokens").doc();
					var promoUrl = 'https://partypay.nl/link?type=tokens&sharecode=' + eref.id + '_' + this.props.observableStore.currEvent.ID;
					var edata = {url: promoUrl, note: this.state.promoNote, tokens: parseFloat(this.state.tokenAantal), tijd: Math.floor(new Date() / 1000), type: 'gift'};
					if(this.state.promoMultiUser){edata['multiUser'] = true}
					if(this.state.promoPaid){edata['paid'] = true}
					eref.set(edata);
					promoUrls.push(promoUrl);	
				}	
				if(this.state.promoAction === 'mail'){
					var sendPromoFunc = functions.httpsCallable('sendMail');
					sendPromoFunc({mailType: 'promo', eventName: this.props.observableStore.currEvent.naam, reciever: this.state.promoMail, promoUrl: promoUrls[0], aantal: this.state.tokenAantal});
				}
				if(this.state.promoAction === 'whatsapp'){
					var whatsappUrl = 'https://wa.me/?text=Klik op de volgende link om ' + this.state.tokenAantal + ' muntjes toe te voegen. ' + promoUrls[0]; 
					window.open(whatsappUrl,'_blank');
				}
				if(this.state.promoAction === 'csv'){this.exportCsv(promoUrls)}
				this.togglePromoModal();
				this.setState({loaded: false, promoties: []},() => {
					this.lastOrder = 100000000000000;
					setTimeout(() => this.getPromoties(), 500);
				})
			}	
		}
	}

	reqFields(){
		var reqFields = [];
		if(!this.state.promoNote || this.state.promoNote === ''){reqFields.push('promoNote')}
		if(this.state.promoAction === 'mail' && !this.re.test(String(this.state.promoMail).toLowerCase())){reqFields.push('promoMail')}
		if(this.state.promoAction === 'mail' && this.state.promoText === ''){reqFields.push('promoText')}
		if(reqFields.length > 0){
			this.setState({reqFields: reqFields});
			return false;
		}
		return true;
	}

	getPromoties(){
		if(this.lastOrder === 100000000000000){this.setState({loaded: false})}
		var promoties = this.state.promoties;
		db.collection("events").doc(this.props.observableStore.currEvent.ID).collection('tokens').where("type", "==", "gift").orderBy("tijd", "desc").startAfter(this.lastOrder).limit(30).get().then(querySnapshot => {
			querySnapshot.forEach(doc => {
				this.lastOrder = doc.data().tijd; 
				promoties.push({code: doc.id, url: doc.data().url, tokens: doc.data().tokens, note: doc.data().note, tijd: doc.data().tijd, multiUser: doc.data().multiUser})
			});
			this.setState({promoties: promoties, loaded: true});
		});
	}

	lastOrder = 100000000000000;
	
	selectPromo(code){
		var selectedCodes = this.state.selectedCodes;
		if(selectedCodes.includes(code)){selectedCodes = selectedCodes.filter(e => e !== code)}
		else{selectedCodes.push(code)}
		this.setState({selectedCodes: selectedCodes});
	}

	deletePromocode(id) {		
		db.collection("events").doc(this.props.observableStore.currEvent.ID).collection('tokens').doc(id).delete().then(() => {
			this.lastOrder = 100000000000000; 
			this.setState({promoties: []},()=>{this.getPromoties()})
		});
	}
	
	deleteMultiple(){
		this.state.selectedCodes.forEach(code => {this.deletePromocode(code)})
	}
	
	copyMultiple(){
		var string = "";		
		this.state.selectedCodes.forEach(code => {string = string + this.state.promoties.find(p => p.code === code).url + "\n"})
		navigator.clipboard.writeText(string)
	}

	exportAll(){
		db.collection("events").doc(this.props.observableStore.currEvent.ID).collection('tokens').where("type", "==", "gift").get().then(querySnapshot => {
			var promoties = [];
			querySnapshot.forEach(doc => {promoties.push({url: doc.data().url, tokens: doc.data().tokens, note: doc.data().note})});
			var lockersCsv = '';
			promoties.forEach(promo => {lockersCsv = lockersCsv + promo.url + ',' + promo.tokens + ' tokens,' + promo.note + "\r\n"});
			const blob = new Blob([lockersCsv], { type: 'text/csv;charset=utf-8;' });
			const url = URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = 'export_urls';
			a.click();
			a.remove();
		});
	}

	exportCsv(urls){
		var lockersCsv = '';
		urls.forEach(url => {lockersCsv = lockersCsv + url + "\r\n"});
		const blob = new Blob([lockersCsv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'export_urls';
        a.click();
        a.remove();
	}
		
	re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	
	uploadExcel(type){
		if(this.props.observableStore.isAdmin){
			if(type === 'mails'){alert('Upload een Excel-bestand waarin de linkerkolom de e-mailadressen staan en de rechterkolom het aantal munten dat elke e-mail krijgt.')}
			if(type === 'cardtopup'){alert('Upload een Excel-bestand waarin de linkerkolom de QR-code waarden van de bandjes/pasjes staan en de rechterkolom het aantal munten dat bij elk bandje/pasje moet worden toegevoegd.')}
			if(type === 'cardread'){alert('Upload een Excel-bestand waarin de linkerkolom de QR-code waarden van de bandjes/pasjes staan, zodat alle totale data uitgelezen kan worden.')}
			this.setState({uploadType: type},()=>{this.upload.value = null; this.upload.click()})
		}
		else{alert('Je beschikt niet over de juiste bevoegdheden om deze functie te gebruiken. Neem contact op met jouw accountmanager voor meer informatie.')}
	}

	readExcel(event){
		var reader = new FileReader();	
		if(event.target.files[0] && event.target.files[0].name.slice(-3) === 'lsx'){		
			var [file] = event.target.files;
			reader.onload = (e) => {
				const bstr = e.target.result;
				const wb = XLSX.read(bstr, { type: "binary" });
				const wsname = wb.SheetNames[0];
				const ws = wb.Sheets[wsname];
				this.processList(XLSX.utils.sheet_to_csv(ws, { header: 1 }));
			};
			reader.readAsBinaryString(file);
		}
		else if(event.target.files[0] && event.target.files[0].name.slice(-3) === 'csv'){	
			reader.onload = (e) => {this.processList(reader.result)}
			reader.readAsText(event.target.files[0]);
		}
		else{alert("Upload een excel of csv bestand")}
	}

	processList = (sheet) => {
		if(this.state.uploadType === 'mails'){this.checkMails(sheet)}
		if(this.state.uploadType === 'cardtopup'){this.checkCards(sheet)}
		if(this.state.uploadType === 'cardread'){this.readCards(sheet)}
	}

	checkMails = (sheet) => {
		var maillist = [];
		sheet.split('\n').forEach(item => {
			if(this.isValidEmail(item.split(',')[0])){maillist.push({mail: item.split(',')[0], tokens: item.split(',')[1]})}
		})
		if(maillist.length > 0){this.setState({mailListModal: true, maillist: maillist})}
		else{alert('Er zijn geen e-mailadressen gevonden')}
	}

	checkCards = (sheet) => {
		var cardlist = [];
		sheet.split('\n').forEach(item => {
			if(item.split(',')[0].includes("https://card.partypay.nl/?id=")){
				var url = new URL(item.split(',')[0]);
				cardlist.push({cardID: url.searchParams.get("id"), tokens: item.split(',')[1]});
			}
		})
		if(cardlist.length > 0){this.setState({cardTopupModal: true, cardlist: cardlist})}
		else{alert('Geen bandjes/pasjes gevonden')}
	}

	readCards = (sheet) => {
		var readCardlist = [];
		sheet.split('\n').forEach(item => {
			if(item.includes("https://card.partypay.nl/?id=")){
				var url = new URL(item.split(',')[0]);
				readCardlist.push(url.searchParams.get("id"));
			}
		})
		if(readCardlist.length > 0){this.setState({readCardModal: true},()=>this.readCardsDB(readCardlist))}
		else{alert('Geen bandjes/pasjes gevonden')}
	}

	delMail = (index) => {
		var maillist = this.state.maillist;
		maillist.splice(index, 1);
		this.setState({maillist: maillist});
	}	

	delCard = (index) => {
		var cardlist = this.state.cardlist;
		cardlist.splice(index, 1);
		this.setState({cardlist: cardlist});
	}	
		
	sendMailListPromo = () => {
		var maillist = this.state.maillist;
		if(maillist.length > 0){
			this.setState({mailPromoLoad: true});
			for(let i = 0; i < maillist.length; i++){
				const eventRef = db.collection("events").doc(this.props.observableStore.currEvent.ID).collection("tokens").doc();
				maillist[i].promo = 'https://partypay.nl/link?type=tokens&sharecode=' + eventRef.id + '_' + this.props.observableStore.currEvent.ID;
				eventRef.set({url: maillist[i].promo, note: this.state.mailListNote + ' - ' + maillist[i].mail, tokens: parseFloat(maillist[i].tokens), tijd: Math.floor(new Date() / 1000), type: 'gift'});
			}	
			var sendPromoFunc = functions.httpsCallable('sendMail');
			sendPromoFunc({mailType: 'promoBatch', mailList: maillist, eventName: this.props.observableStore.currEvent.naam});
			this.toggleMailListModal();
			this.getPromoties();
		}
	}

	topupCards = () => {
		const batch = db.batch();
		this.state.cardlist.forEach(card => {
			const usersRef = db.collection("events").doc(this.props.observableStore.currEvent.ID).collection("users").doc();
			batch.set(usersRef, {tokens: card.tokens, promotokens: card.tokens, cards: [card.cardID]});
		});
		batch.commit().then(() => this.toggleCardTopupModal()).catch(error => this.setState({topupCardLoad: false},()=>alert(error)));
	}

	readCardsDB = async (readCardlist) => {
		const usersRef = db.collection("events").doc(this.props.observableStore.currEvent.ID).collection("users")
		const cardChunks = this.chunkArray(readCardlist, 10);
		const queryPromises = cardChunks.map(chunk => usersRef.where("cards", "array-contains-any", chunk).get());
		const snapshots = await Promise.all(queryPromises);
		var total = {users: 0, tokens: 0, promotokens: 0};
		snapshots.forEach(snapshot => {total.users += snapshot.size; snapshot.forEach(doc => {
			if(doc.data().tokens){total.tokens += parseFloat(doc.data().tokens)}
			if(doc.data().promotokens){total.promotokens += parseFloat(doc.data().promotokens)}
		})});
		this.setState({readCardLoad: false, readCardTotal: total});
	}

 	isValidEmail = (email) => {const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; return pattern.test(email)}
	chunkArray = (arr, size) => arr.reduce((acc, _, i) => i % size ? acc : [...acc, arr.slice(i, i + size)], []);

}

export default Barren;