import React from 'react';
import arrayMove from 'array-move';
import 'react-image-picker/dist/index.css';
import {Table, InputGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroupAddon, InputGroupText, FormGroup, Label, Input, Row, Col} from 'reactstrap';
import {SortableContainer, SortableElement, sortableHandle} from 'react-sortable-hoc';
import { observer, inject } from 'mobx-react';
import { db, fieldvalue, rtdb, functions } from '../components/firebase/firebase';
import Datetime from 'react-datetime';
import Warning from '../assets/images/pp_warning.svg';
import * as XLSX from "xlsx";

@inject('observableStore')
@observer
class Menu extends React.Component {
	render() {
		return (
			<div style={{paddingLeft: 10, paddingRight: 10, paddingBottom: 100}}>
				<Row>
					<Col lg={12}>
						<h2 className="font-light text-muted topTitle" style={{fontSize: 28, fontWeight: 900, color: '#212529'}}>Lockers</h2>
					</Col>					
				</Row>				
				<Row style={{marginTop: 12, paddingLeft: 15, paddingRight: 15, }}>
					<Col lg={2} style={{paddingLeft: 0, paddingRight: 0}}></Col>
					<Col className="menuRightActions">						
						<div className="addBtnTop" style={{marginRight: 15, width: 190, marginBottom: 0, background: '#d12a5e', color: '#fff'}} onClick={() => this.toggleDate()}>
							Periode toevoegen								
						</div>
					</Col>
				</Row>				
				<Row className="menuTopCats">
					<Col lg={2} className="graph-display" style={{background: 'none', paddingTop: 0, paddingRight: 0, paddingLeft: 0, minWidth: 275}}>
						{this.getLockerDates().length > 0 ?
							<div>
								{this.getLockerDates().map((date, ind) => (
								<div key={ind} className="menucatnav" style={{backgroundColor: this.state.currentDate === ind ? '#f6f6f6' : '', borderLeftWidth: this.state.currentDate === ind ? 6 : 0}}>
									<div onClick={() => this.setState({currentDate: ind})} style={{cursor:'pointer'}}>
										<div><span>{date.desc}</span></div>
										<div style={{marginTop: -5}}><span style={{fontSize: 10, color: 'rgba(0,0,0,0.5)'}}>{this.formatDate(date.start)} / {this.formatDate(date.end)}</span></div>
									</div>
									<div style={{marginLeft: 10, cursor:'pointer'}}>
										<i class="fa fa-edit hightlightIcon2" onClick={() => this.editDate(ind)}></i>
									</div>
								</div>
								))}
							</div>
						:
							<div className="menucatnav" style={{width: '100%'}}>
								<span style={{width: '100%', textAlign: 'center', marginTop: 70, marginLeft: -20, color:'rgba(0,0,0,0.5)'}}>Nog geen periodes</span>
							</div>
						}
					</Col>
					
					<Col className="graph-display graph-menu graph-scroll" style={{marginLeft: 20, padding: 40, paddingTop: 30, minHeight: 500}}>
						<div>
							{this.props.observableStore.currEvent.kluizen && this.getLockerDates()[this.state.currentDate] ?
							<div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 5}}>
								<h2 className="font-light text-muted" style={{marginTop: 10, marginRight: 20, fontSize: 20, fontWeight: 900, color: '#212529',}}>Lockers</h2>
								<div style={{display: 'flex'}}>
									<div className="addBtnLst" style={{width: 190, background: 'rgba(0,0,0,0.03)'}} onClick={() => this.toggleLocker()}>
										Locker toevoegen										
									</div>
								</div>
							</div>
							:null}
							<div>
								{this.props.observableStore.currEvent.kluizen && this.getLockerDates()[this.state.currentDate] && this.getLockerDates()[this.state.currentDate].lockers &&this.getLockerDates()[this.state.currentDate].lockers.length > 0 ?
									<div style={{minHeight: 450}}>
										<SortableLockerList items={this.getLockerDates()[this.state.currentDate].lockers ? this.getLockerDates()[this.state.currentDate].lockers : []} editLocker={this.editLocker} delLocker={this.delLocker} onSortEnd={this.onLockerSortEnd} useDragHandle={true} />
									</div>
								:
									<div className="emptyContainer" style={{borderWidth: 0, minHeight: 450, flexDirection: 'column', justifyContent: 'center'}}>
										<img src={Warning} style={{width: 50, marginBottom: 15}} />
										<span>Er zijn nog geen lockers toegevoegd</span>
									</div>
								}
							</div>
						</div>
					</Col>
				</Row>			
				<Modal isOpen={this.state.saveModal} toggle={this.toggleSaveModal} className={this.props.className} centered >
					<ModalHeader toggle={this.toggleSaveModal}>Wijzigingen opslaan</ModalHeader>
					<ModalBody>
						<p style={{marginTop: 10}}>
							Wijzigingen zijn opgeslagen.
						</p>
					</ModalBody>
					<ModalFooter>
						<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={this.toggleSaveModal}>
						  Sluit venster
						</Button>
					</ModalFooter>
				</Modal>
				<Modal isOpen={this.state.dateModal} toggle={() => this.toggleDate()} className={this.props.className} centered style={{maxWidth: 370}}>
					<ModalHeader toggle={() => this.toggleDate()}>Periode  {this.state.editDateInd >= 0 ? 'aanpassen' : 'toevoegen'}</ModalHeader>
					<ModalBody>
						<FormGroup>
							<Label htmlFor="example-email">Omschrijving</Label>
							<Input style={{height: 40, borderColor: this.state.reqFields.includes('newDateDesc') ? 'red' : undefined}} type="text" id="example-email" name="price" placeholder="Bijv: Zaterdag of Happy Feelings" value={this.state.newDateDesc} onChange={e => this.setState({newDateDesc: e.target.value})}/>
						</FormGroup>
						<FormGroup>
							<Label htmlFor="example-email" style={{marginBottom: 10}}>Start datum/tijd</Label>
							<div className="lockerDate" onClick={() => {if(this.state.editDateInd >= 0){alert('Je kunt deze datum niet meer wijzigen.')}}}>
								<i class="fa fa-calendar-day" style={{position: 'absolute', right: 15, top: 13, color: '#525f7f'}}></i>
								<Datetime inputProps={{disabled: this.state.editDateInd >= 0 ? true : false}} timeFormat={true} value={this.state.newDateStart} onChange={e => this.setState({newDateStart: e})} closeOnSelect={true} dateFormat="dddd D MMMM YYYY" locale="nl"/>
							</div>
						</FormGroup>
						<FormGroup>
							<Label htmlFor="example-email" style={{marginBottom: 10}}>Einde datum/tijd</Label>
							<div className="lockerDate" onClick={() => {if(this.state.editDateInd >= 0){alert('Je kunt deze datum niet meer wijzigen.')}}}>
								<i class="fa fa-calendar-day" style={{position: 'absolute', right: 15, top: 13, color: '#525f7f'}}></i>
								<Datetime inputProps={{disabled: this.state.editDateInd >= 0 ? true : false}} timeFormat={true} value={this.state.newDateEnd} onChange={e => this.setState({newDateEnd: e})} closeOnSelect={true} dateFormat="dddd D MMMM YYYY" locale="nl"/>
							</div>
						</FormGroup>
					</ModalBody>
					<ModalFooter style={{justifyContent: 'space-between'}}>
						<div>
							{this.state.editDateInd >= 0 ?
							<Button style={{backgroundColor: 'rgb(229, 57, 53)', border: 0}} onClick={this.delDate}>
							  verwijderen
							</Button>
							:null}
						</div>
						<div style={{display: 'flex'}}>
							<Button className="cancel_btn" onClick={() => this.toggleDate()}>
							  Annuleren
							</Button>
							<Button style={{backgroundColor: '#d12a5e', border: 0, marginLeft: 10}} onClick={this.addDate}>
							  {this.state.editDateInd >= 0 ? 'Aanpassen' : 'Toevoegen'}
							</Button>
						</div>
					</ModalFooter>
				</Modal>



				<Modal isOpen={this.state.lockerModal} toggle={this.toggleLocker} className={this.props.className} centered style={{maxWidth: 430}}>
					<ModalHeader toggle={this.toggle} style={{marginBottom: 10}}>
						<a onClick={() => this.setState({lockerTab: 'info'})} style={{cursor: 'pointer', fontSize: 15, color: this.state.lockerTab === 'info' ? '#000' : 'rgba(0,0,0,0.5)'}}>Instellingen</a> 
						<a onClick={() => this.setState({lockerTab: 'codes'},()=>this.getCodes())} style={{cursor: 'pointer', marginLeft: 25, fontSize: 15, color: this.state.lockerTab === 'codes' ? '#000' : 'rgba(0,0,0,0.5)'}}>Lijst</a>
						<a onClick={() => this.setState({lockerTab: 'stats'},()=>this.getStats())} style={{cursor: 'pointer', marginLeft: 25, fontSize: 15, color: this.state.lockerTab === 'stats' ? '#000' : 'rgba(0,0,0,0.5)'}}>Data</a>
						<div style={{marginLeft: this.state.lockerTab === 'info' ? -6 : (this.state.lockerTab === 'codes' ? 102 : 162), width: this.state.lockerTab === 'info' ? 86 : 50, height: 1, backgroundColor: '#d12a5e', position: 'absolute', marginTop: 16}}></div>
					</ModalHeader>	
					{this.state.lockerTab === 'info' ?			
					<ModalBody>
						<FormGroup>
							<Label htmlFor="example-email">Omschrijving</Label>
							<Input style={{height: 40, borderColor: this.state.reqFields.includes('newLockerDesc') ? 'red' : undefined}} type="text" id="example-email" name="price" placeholder="Bijv: Medium locker of Camping locker" value={this.state.newLockerDesc} onChange={e => this.setState({newLockerDesc: e.target.value})}/>
						</FormGroup>
						<Row>
							<Col lg={4}>
							<FormGroup>
								<Label htmlFor="example-email">Aantal</Label>
								<Input style={{height: 40, borderColor: this.state.reqFields.includes('newLockerAmount') ? 'red' : undefined}} type="number" id="example-email" name="price" placeholder="Bijv: 10" value={this.state.newLockerAmount} onChange={e => this.setState({newLockerAmount: parseFloat(e.target.value)})}/>
							</FormGroup>
							</Col>
							<Col lg={8}>
								<FormGroup>
									<Label htmlFor="example-email">Status</Label>
									<Input style={{height: 40, borderColor: this.state.reqFields.includes('newLockerState') ? 'red' : undefined}} type="select" className='minimal' onChange={e => this.setState({newLockerState: e.target.value})}>
										<option value="" selected={this.state.newLockerState === ""}>Selecteer een status</option>
										<option value="Niet beschikbaar" selected={this.state.newLockerState === "Niet beschikbaar"}>Niet beschikbaar</option>
										<option value="Beschikbaar" selected={this.state.newLockerState === "Beschikbaar"}>Beschikbaar</option>
										<option value="Uitverkocht" selected={this.state.newLockerState === "Uitverkocht"}>Uitverkocht</option>
									</Input>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col lg={4}>
								<label htmlFor="example-email">Prijs</label>
								<InputGroup>
									<InputGroupAddon addonType="prepend">
										<InputGroupText style={{background: '#fff', borderColor: this.state.reqFields.includes('newLockerPrice') ? 'red' : undefined}}><i class="fa fa-euro-sign"></i></InputGroupText>
									</InputGroupAddon>
									<Input style={{borderLeftWidth: 0, paddingLeft: 0, borderColor: this.state.reqFields.includes('newLockerPrice') ? 'red' : undefined}} type="text" className="FullColorPlaceholder" placeholder="0,00" value={this.state.newLockerPrice.replace(".",",")} onChange={e => this.setState({newLockerPrice: e.target.value})}/>
								</InputGroup>
							</Col>
							<Col lg={8}>
								<FormGroup>
									<Label htmlFor="example-email">Leverancier</Label>
									<Input style={{height: 40, borderColor: this.state.reqFields.includes('newLockerSupplier') ? 'red' : undefined}} type="select" className='minimal' onChange={e => this.setState({newLockerSupplier: e.target.value})}>
										<option value="" selected={this.state.newLockerSupplier === ""}>Selecteer leverancier</option>
										<option value="partylockers" selected={this.state.newLockerSupplier === "partylockers"}>Partylockers</option>
										<option value="eventsafe" selected={this.state.newLockerSupplier === "eventsafe"}>Eventsafe</option>
										<option value="lockercompany" selected={this.state.newLockerSupplier === "lockercompany"}>Locker Company</option>
										<option value="elockers" selected={this.state.newLockerSupplier === "elockers"}>Elockers</option>
										<option value="lockerbox" selected={this.state.newLockerSupplier === "lockerbox"}>Lockerbox</option>
										<option value="olssen" selected={this.state.newLockerSupplier === "olssen"}>Olssen</option>
									</Input>
								</FormGroup>
							</Col>
						</Row>
						{this.state.newLockerSupplier === "olssen" ?
						<Row>
							<Col ld={12}>
							<div className="switchBtn" style={{marginTop: 8}}>
								<label htmlFor="staticEmail" className="col-form-label">Lockers via Keynius</label>
								<div style={{cursor: 'pointer', height: 29, width: 55, padding: 3, backgroundColor: this.state.newLockerKeynius ? '#d12a5e' : '#ccc', borderRadius: 25}} onClick={() => {if(this.state.newLockerKeynius){this.setState({newLockerKeynius: false})}else{this.toggleKeyniusModal()}}}>
									<div style={{height: 23, width: 23, borderRadius: 12, backgroundColor: '#fff', float: this.state.newLockerKeynius ? 'right' : 'left'}}></div>
								</div>
							</div>	
							</Col>
						</Row>
						:null}
						{this.state.newLockerSupplier === "lockercompany" ?
						<Row>
							<Col ld={12}>
							<div className="switchBtn" style={{marginTop: 8}}>
								<label htmlFor="staticEmail" className="col-form-label">Connect with Locker Company</label>
								<div style={{cursor: 'pointer', height: 29, width: 55, padding: 3, backgroundColor: this.state.newLockerLC && this.state.newLockerLC.connect ? '#d12a5e' : '#ccc', borderRadius: 25}} onClick={() => this.connectLockerCompany('events')}>
									<div style={{height: 23, width: 23, borderRadius: 12, backgroundColor: '#fff', float: this.state.newLockerLC && this.state.newLockerLC.connect ? 'right' : 'left'}}></div>
								</div>
							</div>	
							</Col>
						</Row>
						:null}
					</ModalBody>
					:null}
					{this.state.lockerTab === 'codes' ?	
					<ModalBody>
						<div style={{height: 450, overflowY: 'scroll'}}>
							{this.state.newLockerSupplier !== 'partylockers' ?
							<Table className="v-middle">
								<thead>
									<tr>
										<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Nummer</td>
										<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Code</td>
									</tr>
								</thead>
								<tbody>
								{Object.values(this.state.currKluizen).map((kluis, index) => (								
									<tr style={{background: index%2 === 0 ? null : 'rgba(0,0,0,0.03)'}}>
										<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{kluis.num}</h6></td>
										<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{kluis.code}</h6></td>											
										<td style={{minWidth: 90, padding: 20, fontSize: 18, padding: 0, textAlign: 'right'}}>
											<i class="fa fa-trash hightlightIcon2" onClick={() => this.delKluis(kluis.num)} style={{cursor:'pointer', marginRight: 25}}></i>
										</td>	
									</tr>
								))}
								</tbody>
							</Table>
							:
							<Table className="v-middle">
								<thead>
									<tr>
										<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Nummer</td>
										<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Wand</td>
									</tr>
								</thead>
								<tbody>
								{Object.values(this.state.currKluizen).map((kluis, index) => (								
									<tr style={{background: index%2 === 0 ? null : 'rgba(0,0,0,0.03)'}}>
										<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{kluis}</h6></td>
										<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{kluis.split('.')[0]}</h6></td>											
										<td style={{minWidth: 90, padding: 20, fontSize: 18, padding: 0, textAlign: 'right'}}>
											<i class="fa fa-trash hightlightIcon2" onClick={() => this.delKluis(kluis)} style={{cursor:'pointer', marginRight: 25}}></i>
										</td>	
									</tr>
								))}
								</tbody>
							</Table>
							}
							{this.state.loadingLockerCodes ? 
								<div className="emptyContainer" style={{position: 'absolute', top: 0, left: 0, borderWidth: 0, minHeight: 450, flexDirection: 'column', justifyContent: 'center'}}>
									<div class="loading-spinner" style={{height: 50, width: 50, marginBottom: 40}}></div>
									<span>Kluis codes ophalen</span>
								</div>
							:
							<div>
								{Object.values(this.state.currKluizen).length === 0 ? 
									<div className="emptyContainer" style={{position: 'absolute', top: 0, left: 0, borderWidth: 0, minHeight: 450, flexDirection: 'column', justifyContent: 'center'}}>
										<img src={Warning} style={{width: 50, marginBottom: 15}} />
										<span>Er zijn nog geen codes toegevoegd</span>
									</div>
								:null}
							</div>
							}
						</div>
					</ModalBody>
					:null}
					{this.state.lockerTab === 'stats' ?	
					<ModalBody>
						<div style={{height: 230, overflowY: 'scroll'}}>
							<h2 className="font-light text-muted topTitle" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.5)', marginBottom: 25}}>{this.getLockerDates()[this.state.currentDate].desc} {'>'} {this.state.newLockerDesc}</h2>
							{this.state.loadingLockerStats ? 
								<div className="emptyContainer" style={{position: 'absolute', top: 0, left: 0, borderWidth: 0, minHeight: 300, flexDirection: 'column', justifyContent: 'center'}}>
									<div class="loading-spinner" style={{height: 50, width: 50, marginBottom: 40}}></div>
									<span>Locker statistieken ophalen</span>
								</div>
							:
							<Table className="v-middle">
								<tbody>							
									<tr>
										<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>Totaal beschikbaar</h6></td>
										<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{this.state.newLockerAmount}</h6></td>	
									</tr>
									<tr style={{background: 'rgba(0,0,0,0.03)'}}>
										<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>Aantal verkocht</h6></td>	
										<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{this.state.lockersSold}</h6></td>											
									</tr>
									<tr>
										<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>Aantal beschikbaar</h6></td>	
										<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{this.state.newLockerAmount - this.state.lockersSold}</h6></td>											
									</tr>
								</tbody>
							</Table>
							}
						</div>
					</ModalBody>
					:null}
					<ModalFooter style={{justifyContent: 'space-between'}}>
						{this.state.lockerTab === 'info' ?	
						<div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
							<Button className="cancel_btn" onClick={this.toggleLocker}>
							  Annuleren
							</Button>
							<Button style={{backgroundColor: '#d12a5e', border: 0, marginLeft: 10}} onClick={this.addLocker}>
							  {this.state.editLockerInd >= 0 ? 'Aanpassen' : 'Toevoegen'}
							</Button>
						</div>
						:
						<div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
							<Button className="menuDelButton" onClick={() => this.emptyKluizen()}>
							 Wissen
							</Button>
							{!this.state.newLockerKeynius ?
							<div style={{display: 'flex'}}>
								<Button style={{marginLeft: 10}} className="cancel_btn" onClick={() => this.exportCsv()}>
								Exporteren
								</Button>
								<Button style={{backgroundColor: '#d12a5e', border: 0, marginLeft: 10}} onClick={() => this.addLockerList()}>
								Lockers toevoegen
								</Button>
								<input type="file" name="upadlocsv" id="upadlocsv" style={{display: 'none'}} ref={(ref) => this.upload = ref} onChange={this.importLockerList.bind(this)}/>
							</div>
							:null}
						</div>
						}
					</ModalFooter>
				</Modal>

				<Modal isOpen={this.state.keyniusModal} toggle={() => this.toggleKeyniusModal()} className={this.props.className} centered style={{maxWidth: 370}}>
					<ModalHeader toggle={() => this.toggleKeyniusModal()}>Inloggen Keynius</ModalHeader>
					<ModalBody>
						{this.state.keyniusState === 0 ?
						<div>
							<center><img src="https://keynius.eu/wp-content/uploads/2023/01/keynius-logo-redesign_1a_green-01.png" style={{width: 300}}></img></center>
							<FormGroup>
								<Label htmlFor="example-email">E-mailadres</Label>
								<Input style={{height: 40, borderColor: this.state.reqFields.includes('keyniusMail') ? 'red' : undefined}} type="text" id="example-email" value={this.state.keyniusMail} onChange={e => this.setState({keyniusMail: e.target.value})}/>
							</FormGroup>
							<FormGroup>
								<Label htmlFor="example-email">Wachtwoord</Label>
								<Input style={{height: 40, borderColor: this.state.reqFields.includes('keyniusPass') ? 'red' : undefined}} type="password" id="example-email" value={this.state.keyniusPass} onChange={e => this.setState({keyniusPass: e.target.value})}/>
							</FormGroup>
						</div>
						:null}
						{this.state.keyniusState === 1 ?
						<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 30}}>
							<div class="loading-spinner" style={{height: 40, width: 50, marginBottom: 40}}></div>
							<span>Inloggen bij keynius</span>
						</div>
						:null}
						{this.state.keyniusState === 2 ?
						<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 30}}>
							<img src={Warning} style={{width: 50, marginBottom: 15}} />
							<span>Er is iets mis gegaan</span>
						</div>
						:null}
					</ModalBody>
					<ModalFooter style={{justifyContent: 'space-between'}}>
						{this.state.keyniusState === 0 ?
						<div style={{display: 'flex'}}>
							<Button className="cancel_btn" onClick={() => this.toggleKeyniusModal()}>
							  Annuleren
							</Button>
							<Button style={{backgroundColor: '#d12a5e', border: 0, marginLeft: 10}} onClick={() => this.keyniusLogin()}>
							  Inloggen
							</Button>
						</div>
						:null}
					</ModalFooter>
				</Modal>

				<Modal isOpen={this.state.keyniusSiteModal} toggle={() => this.toggleKeyniusSiteModal()} className={this.props.className} centered style={{maxWidth: 370}}>
					<ModalHeader toggle={() => this.toggleKeyniusSiteModal()}>Keynius lockers ophalen</ModalHeader>
					<ModalBody>
						{this.state.newLockerKeynius ?
						<Row>
							<Col ld={12}>
								<FormGroup>
									<label htmlFor="staticEmail" className="col-form-label" style={{marginBottom: 2}}>Locker locaties</label>
									<Input type="select" className='minimal' style={{width: '100%', height: 50, paddingBottom: 10, paddingTop: 10, paddingRight: 10, borderColor: 'rgb(217, 217, 217)', borderRadius: 12}} onChange={e => this.setState({keyniusSite: e.target.value})}>
										<option value={''} selected={this.state.keyniusSite === ''}>Selecteer een locatie</option>
										{Object.keys(this.state.keyniusLockerObj).map(site => (
											<option value={site} selected={this.state.keyniusSite === site}>{site}</option>
										))}
									</Input>
								</FormGroup>
								{this.state.keyniusSite !== '' ?
								<FormGroup>
									<label htmlFor="staticEmail" className="col-form-label" style={{marginBottom: 2}}>Locker formaat</label>
									<Input type="select" className='minimal' style={{width: '100%', height: 50, paddingBottom: 10, paddingTop: 10, paddingRight: 10, borderColor: 'rgb(217, 217, 217)', borderRadius: 12}} onChange={e => this.setState({keyniusSize: e.target.value})}>
										<option value={''} selected={this.state.keyniusSite === ''}>Selecteer een formaat</option>
										{Object.keys(this.state.keyniusLockerObj[this.state.keyniusSite].formats).map(formaat => (
											<option value={formaat} selected={this.state.keyniusSize === formaat}>{formaat}</option>
										))}
									</Input>
								</FormGroup>
								:null}
							</Col>
						</Row>
						:null}
					</ModalBody>
					<ModalFooter style={{justifyContent: 'space-between'}}>
						<div style={{display: 'flex'}}>
							<Button className="cancel_btn" onClick={() => this.toggleKeyniusSiteModal()}>
							  Annuleren
							</Button>
							<Button style={{backgroundColor: '#d12a5e', border: 0, marginLeft: 10}} onClick={() => this.addKeyniusLockers()}>
							  Opslaan
							</Button>
						</div>
					</ModalFooter>
				</Modal>


				<Modal isOpen={this.state.lockerCompanyModal} toggle={() => this.toggleLockerCompanyModal()} className={this.props.className} centered style={{maxWidth: 370}}>
					<ModalHeader toggle={() => this.toggleLockerCompanyModal()}>Locker Company Connect</ModalHeader>
					<ModalBody>
						<Row>
							<Col ld={12}>
								{this.state.lockerCompanyState === 0 ?
								<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 30}}>
									<div class="loading-spinner" style={{height: 40, width: 50, marginBottom: 40}}></div>
									<span style={{textAlign: 'center'}}>Evenementen ophalen bij Locker Company</span>
								</div>
								:null}
								{this.state.lockerCompanyState === 1 ?
								<FormGroup>
									<label htmlFor="staticEmail" className="col-form-label" style={{marginBottom: 2}}>Evenement</label>
									<Input type="select" className='minimal' style={{width: '100%', height: 50, paddingBottom: 10, paddingTop: 10, paddingRight: 10, borderColor: 'rgb(217, 217, 217)', borderRadius: 12}} onChange={e => this.setState({lockerCompanyEventID: e.target.value})}>
										<option value={''} selected={this.state.lockerCompanyEventID === 0}>Selecteer een evenement</option>
										{this.state.lockerCompanyEvents.map(event => (
											<option value={event.id} selected={this.state.lockerCompanyEventID === event.id}>{event.name}</option>
										))}
									</Input>
								</FormGroup>
								:null}
								{this.state.lockerCompanyState === 2 ?
								<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 30}}>
									<div class="loading-spinner" style={{height: 40, width: 50, marginBottom: 40}}></div>
									<span style={{textAlign: 'center'}}>Evenement data ophalen bij Locker Company</span>
								</div>
								:null}	
								{this.state.lockerCompanyState === 3 ?
								<div>
									<FormGroup>
										<label htmlFor="staticEmail" className="col-form-label" style={{marginBottom: 2}}>Locker periode</label>
										<Input type="select" className='minimal' style={{width: '100%', height: 50, paddingBottom: 10, paddingTop: 10, paddingRight: 10, borderColor: 'rgb(217, 217, 217)', borderRadius: 12}} onChange={e => this.setLockerPeriod(e.target.value)}>
											<option value={''} selected={this.state.lockerCompanyPeriod === 0}>Selecteer een periode</option>
											{this.state.lockerCompanyEventData.periods.map((period, ind) => (
												<option value={ind} selected={this.state.lockerCompanyPeriod === period.id}>{period.texts[0].name}</option>
											))}
										</Input>
									</FormGroup>
									<FormGroup>
										<label htmlFor="staticEmail" className="col-form-label" style={{marginBottom: 2}}>Locker formaat</label>
										<Input type="select" className='minimal' style={{width: '100%', height: 50, paddingBottom: 10, paddingTop: 10, paddingRight: 10, borderColor: 'rgb(217, 217, 217)', borderRadius: 12}} onChange={e => this.setState({lockerCompanySize: e.target.value})}>
											<option value={''} selected={this.state.lockerCompanySize === 0}>Selecteer een formaat</option>
											{this.state.lockerCompanyEventData.locker_sizes.map(size => (
												<option value={size.id} selected={this.state.lockerCompanySize === size.id}>{size.name}</option>
											))}
										</Input>
									</FormGroup>
								</div>
								:null}	
								{this.state.lockerCompanyState === 4 ?
								<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 30}}>
									<img src={Warning} style={{width: 50, marginBottom: 15}} />
									<span>Er is iets mis gegaan</span>
								</div>
								:null}
							</Col>
						</Row>
					</ModalBody>
					<ModalFooter style={{justifyContent: 'space-between'}}>
						<div style={{display: 'flex'}}>
							<Button className="cancel_btn" onClick={() => this.toggleLockerCompanyModal()}>
							  Annuleren
							</Button>
							{this.state.lockerCompanyState === 1 ?
							<Button style={{backgroundColor: '#d12a5e', border: 0, marginLeft: 10}} onClick={() => this.connectLockerCompany('eventData')}>
							  Evenement ophalen
							</Button>
							:null}
							{this.state.lockerCompanyState === 3 ?
							<Button style={{backgroundColor: '#d12a5e', border: 0, marginLeft: 10}} onClick={() => this.connectLockerCompany('lockerInfo')}>
							  Koppel locker data
							</Button>
							:null}
						</div>
					</ModalFooter>
				</Modal>

				<Modal isOpen={this.state.addWallModal} toggle={() => this.toggleWallModal()} className={this.props.className} centered style={{maxWidth: 370}}>
					<ModalHeader toggle={() => this.toggleWallModal()}>Lockerwand toevoegen</ModalHeader>
					<ModalBody>
						<p>Let op: zodra je een lockerwand toevoegt kunnen de lockernummers direct worden opgehaald door bezoekers die een locker hebben gekocht.</p>
						<Row style={{marginTop: 30, marginBottom: 20}}>
							<Col sm={4}>
								<Label htmlFor="example-email">Lockerwand</Label>
								<Input type="select" style={{marginBottom: 8, width: '100%', height: 35}} className='minimal' onChange={e => {this.setState({addWallLet: e.target.value})}}>
									<option value={'A'} selected={this.state.addWallLet === 'A'}>A</option>
									<option value={'B'} selected={this.state.addWallLet === 'B'}>B</option>
									<option value={'C'} selected={this.state.addWallLet === 'C'}>C</option>
									<option value={'D'} selected={this.state.addWallLet === 'D'}>D</option>
								</Input>
							</Col>
							<Col sm={4}>
								<Label htmlFor="example-email">Startnummer</Label>
								<Input type="number" id="example-email" value={this.state.addWallStart} onChange={e => this.setState({addWallStart: e.target.value})}/>
							</Col>
							<Col sm={4}>
								<Label htmlFor="example-email">Eindnummer</Label>
								<Input type="number" id="example-email" value={this.state.addWallEnd} onChange={e => this.setState({addWallEnd: e.target.value})}/>
							</Col>
						</Row>
					</ModalBody>
					<ModalFooter style={{justifyContent: 'space-between'}}>
						{this.state.keyniusState === 0 ?
						<div style={{display: 'flex'}}>
							<Button className="cancel_btn" onClick={() => this.toggleWallModal()}>
							  Annuleren
							</Button>
							<Button style={{backgroundColor: '#d12a5e', border: 0, marginLeft: 10}} onClick={() => this.addLockerWall()}>
							  Toevoegen
							</Button>
						</div>
						:null}
					</ModalFooter>
				</Modal>

			</div>
		);
	}
	
	state = {
		saveModal: false,
		lockerTab: 'info',
		
		currentDate: 0,
		currKluizen: {},

		dateModal: false,
		newDateStart: new Date(),
		newDateEnd: new Date(),
		newDateDesc: '',
		editDateInd: -1,
		
		lockerModal: false,	
		newLockerId: Math.random().toString(36).slice(-10),
		newLockerDesc: '',	
		newLockerSupplier: '',
		newLockerState: '',
		newLockerAmount: 0,
		newLockerPrice: '0,00',
		newLockerKeynius: false,	
		editLockerInd: -1,

		reqFields: [],

		loadingLockerCodes: true,
		loadingLockerStats: true,
		lockersSold: 0,

		keyniusModal: false,
		keyniusMail: '',
		keyniusPass: '',
		keyniusState: 0,
		
		keyniusSiteModal: false,
		keyniusLockerObj: {},
		keyniusSize: '',
		keyniusSite: '',

		lockerCompanyModal: false,
		lockerCompanyState: 0,
		lockerCompanyEvents: [],
		lockerCompanyEventID: 0,
		lockerCompanyEventData: {},
		lockerCompanyPeriod: 0,
		lockerCompanyAssignAt: 0,
		lockerCompanySize: 0,

		addWallModal: false,
		addWallLet: 'A',
		addWallStart: 0,
		addWallEnd: 0

	}

	addLockerList(){
		if(this.state.newLockerSupplier === 'partylockers'){this.toggleWallModal()}
		else{this.upload.click()}
	}

	addLockerWall(){
		const start = parseInt(this.state.addWallStart, 10);
		const end = parseInt(this.state.addWallEnd, 10);
		if(start === 0){alert('Startnummer moet minimaal 1 bedragen'); return null}
		if(start >= end){alert('Eindnummer moet gelijk of groter zijn dan het startnummer'); return null}
		var lockerObj = this.state.currKluizen;
		for(let i = start; i <= end; i++){
			var lockerString = this.state.addWallLet + '.' + i.toString().padStart(3,'0');
			lockerObj[lockerString.replace('.','')] = lockerString;
		}

		this.setState({currKluizen: lockerObj},()=>this.toggleWallModal());
		this.getLockerRef().set({'lockers': lockerObj},{merge: true})
	}

	connectLockerCompany(type){
		if(this.state.newLockerLC && this.state.newLockerLC.connect){
			this.setState({newLockerLC: {connect: false, period: 0, size: 0}}, () => {delete this.state['newLockerLC']});
		}
		else{
			var getLockerCompanyData = functions.httpsCallable('getLockerCompanyData');
			if(type === 'events'){
				this.toggleLockerCompanyModal();
				getLockerCompanyData({}).then(result => {
					if(result.data.state === 1){this.setState({lockerCompanyEvents: result.data.events, lockerCompanyState: 1})}
					else{this.setState({lockerCompanyState: 4})}
				}).catch(e => {console.log(e); this.setState({lockerCompanyState: 4})});
			}
			if(type === 'eventData'){
				this.setState({lockerCompanyState: 2});
				getLockerCompanyData({eid: this.state.lockerCompanyEventID}).then(result => {
					if(result.data.state === 1){this.setState({lockerCompanyEventData: result.data.eventData, lockerCompanyState: 3})}
					else{this.setState({lockerCompanyState: 4})}
				}).catch(e => {console.log(e); this.setState({lockerCompanyState: 4})});
			}
			if(type === 'lockerInfo'){
				this.setState({lockerCompanyModal: false, newLockerLC: {connect: true, period: this.state.lockerCompanyPeriod, assignAt: this.state.lockerCompanyAssignAt, size: this.state.lockerCompanySize}});
			}
		}
	}

	setLockerPeriod(ind){
		var period = this.state.lockerCompanyEventData.periods[ind];
		var assignAt = Math.floor(new Date(period.assign_at).getTime() / 1000);
		this.setState({lockerCompanyPeriod: period.id, lockerCompanyAssignAt: assignAt});
	}

	keyniusLogin(){
		if(this.reqFields('keynius')){
			var keyniusLogin = functions.httpsCallable('keyniusLogin');
			this.setState({keyniusState: 1});
			keyniusLogin({mail: this.state.keyniusMail, pass: this.state.keyniusPass, eid: this.props.observableStore.currEvent.ID, oid: this.props.observableStore.currEvent.oid}).then(result => {
				if(result.data.state === 1){this.setState({keyniusModal: false, newLockerKeynius: true, keyniusSiteModal: true, keyniusLockerObj: result.data.lockers})}
				if(result.data.state === 2){this.setState({keyniusState: 2})}
			}).catch(e => {console.log(e); this.setState({keyniusState: 2})});
		}
	}

	addKeyniusLockers(){
		this.getLockerRef().set({'lockers': this.state.keyniusLockerObj[this.state.keyniusSite].formats[this.state.keyniusSize].lockers},{merge: true});
		this.toggleKeyniusSiteModal();
	}

	getLockerDates(){
		var lockerDates = [];
		if(this.props.observableStore.currEvent.kluizen){
			lockerDates = this.props.observableStore.currEvent.kluizen.sort((a, b) => a.start - b.start);
		}
		return lockerDates;
	}

	toggleSaveModal = () => {this.setState({saveModal: !this.state.saveModal})}
	toggleDate = (edit) => {this.setState({dateModal: !this.state.dateModal, newDateStart: new Date(), newDateEnd: new Date(), newDateDesc: '', editDateInd: -1, reqFields: []})}
	toggleLocker = () => {delete this.state['newLockerLC']; this.setState({lockerModal: !this.state.lockerModal, newLockerId: Math.random().toString(36).slice(-10), newLockerDesc: '', newLockerSupplier: '', newLockerKeynius: false, newLockerState: '', newLockerAmount: 0, newLockerPrice: '0,00', editLockerInd: -1, reqFields: [], lockerTab: 'info', currKluizen: {}, loadingLockerCodes: true, lockersSold: 0, loadingLockerStats: true})}
	toggleKeyniusSiteModal = () => {this.setState({keyniusSiteModal: !this.state.keyniusSiteModal, keyniusLockerObj: [], keyniusSize: '', keyniusSite: '',})}
	toggleKeyniusModal = () => {this.setState({keyniusModal: !this.state.keyniusModal, keyniusMail: '', keyniusPass: '', keyniusState: 0})}
	toggleLockerCompanyModal = () => {this.setState({lockerCompanyModal: !this.state.lockerCompanyModal, lockerCompanyState: 0, lockerCompanyEvents: [], lockerCompanyEventData: {}})}
	toggleWallModal = () => {this.setState({addWallModal: !this.state.addWallModal, addWallLet: 'A', addWallStart: 0, addWallEnd: 0})}

	addDate = () => {		
		if(Math.round(new Date(this.state.newDateEnd).getTime() / 1000) > Math.round(new Date(this.state.newDateStart).getTime() / 1000)){
			if(this.reqFields('period')){
				var lockerObj = [];
				if(this.props.observableStore.currEvent.kluizen){lockerObj = this.getLockerDates()}
				if(this.state.editDateInd >= 0){lockerObj[this.state.editDateInd].desc = this.state.newDateDesc}
				else{
					lockerObj.push({
						id: Math.random().toString(36).slice(-10),
						desc: this.state.newDateDesc,
						start: Math.round(new Date(this.state.newDateStart).getTime() / 1000),
						end: Math.round(new Date(this.state.newDateEnd).getTime() / 1000)
					});
				}
				this.props.observableStore.currEvent.kluizen = lockerObj;
				this.props.observableStore.saveEventInfo('lockers');
				this.toggleDate();
			}
		}
		else{alert("De eind datum moet na de start datum komen");}
	}

	editDate = (ind) => {
		var start = new Date(0); 
		var end = new Date(0); 
		start.setUTCSeconds(this.getLockerDates()[ind].start);
		end.setUTCSeconds(this.getLockerDates()[ind].end);
		this.setState({dateModal: true, newDateStart: start, newDateEnd: end, newDateDesc: this.getLockerDates()[ind].desc,	editDateInd: ind});
	}

	delDate = () => {
		var lockers = this.getLockerDates();
		lockers.splice(this.state.editDateInd, 1);
		this.props.observableStore.currEvent.kluizen = lockers;
		this.props.observableStore.saveEventInfo('lockers');		
		this.toggleDate();
	}

	addLocker = () => {		
		if(this.reqFields('locker')){
			var lockerObj = this.getLockerDates();
			if(this.state.editLockerInd >= 0){
				lockerObj[this.state.currentDate].lockers[this.state.editLockerInd].id = this.state.newLockerId;
				lockerObj[this.state.currentDate].lockers[this.state.editLockerInd].desc = this.state.newLockerDesc;
				lockerObj[this.state.currentDate].lockers[this.state.editLockerInd].supplier = this.state.newLockerSupplier;
				lockerObj[this.state.currentDate].lockers[this.state.editLockerInd].state = this.state.newLockerState;
				lockerObj[this.state.currentDate].lockers[this.state.editLockerInd].amount = this.state.newLockerAmount;
				lockerObj[this.state.currentDate].lockers[this.state.editLockerInd].price = parseFloat(this.state.newLockerPrice.replace(",",".")).toFixed(2).replace(".",",");
				lockerObj[this.state.currentDate].lockers[this.state.editLockerInd].keynius = this.state.newLockerKeynius;
				if(this.state.newLockerLC){lockerObj[this.state.currentDate].lockers[this.state.editLockerInd].lockerCompany = this.state.newLockerLC}
				if(lockerObj[this.state.currentDate].lockers[this.state.editLockerInd].lockerCompany && !this.state.newLockerLC){
					delete lockerObj[this.state.currentDate].lockers[this.state.editLockerInd].lockerCompany;
				}
			}
			else{
				if(!lockerObj[this.state.currentDate].lockers){lockerObj[this.state.currentDate].lockers = []}
				var newLocker = {
					id: this.state.newLockerId,
					desc: this.state.newLockerDesc,
					supplier: this.state.newLockerSupplier,
					state: this.state.newLockerState,
					amount: this.state.newLockerAmount,
					price: parseFloat(this.state.newLockerPrice.replace(",",".")).toFixed(2).replace(".",","),
					keynius: this.state.newLockerKeynius
				};
				if(this.state.newLockerLC){newLocker['lockerCompany'] = this.state.newLockerLC}
				lockerObj[this.state.currentDate].lockers.push(newLocker);
			}
			this.props.observableStore.currEvent.kluizen = lockerObj;
			this.props.observableStore.saveEventInfo('lockers');
			this.toggleLocker();
		}
	}

	editLocker = (ind) => {
		var lockerObj = this.getLockerDates()[this.state.currentDate].lockers[ind];
		var editLockerObj = {
			editLockerInd: ind,
			lockerModal: true,
			newLockerId: lockerObj.id,
			newLockerDesc: lockerObj.desc,
			newLockerSupplier: lockerObj.supplier,
			newLockerState: lockerObj.state,
			newLockerAmount: lockerObj.amount,
			newLockerPrice: lockerObj.price,
			newLockerKeynius: lockerObj.keynius
		};
		if(lockerObj.lockerCompany){editLockerObj['newLockerLC'] = lockerObj.lockerCompany}
		this.setState(editLockerObj);
	}

	delLocker = () => {
		var lockers = this.getLockerDates();
		lockers[this.state.currentDate].lockers.splice(this.state.editLockerInd, 1);
		this.props.observableStore.currEvent.kluizen = lockers;
		this.props.observableStore.saveEventInfo('lockers');		
	}

	onLockerSortEnd = ({oldIndex, newIndex}) => {
		var periodes = this.getLockerDates();
		var sortedLockers = arrayMove(periodes[this.state.currentDate].lockers, oldIndex, newIndex);
		periodes[this.state.currentDate].lockers = sortedLockers
		this.props.observableStore.currEvent.kluizen = periodes;
		this.props.observableStore.saveEventInfo('lockers');	
	}

	delAll = () => {
		this.props.observableStore.currEvent.kluizen = [];
		this.props.observableStore.saveEventInfo('lockers');	
	}

	formatDate(epoch){
		var date = new Date(0);
		date.setUTCSeconds(parseFloat(epoch));
		return ("0" + date.getDate()).slice(-2) + '-' + ("0" + (date.getMonth() +1)).slice(-2) + '-' + date.getFullYear();		
	}

	reqFields(type){
		var reqFields = [];
		if(type === 'period'){
			if(!this.state.newDateDesc || this.state.newDateDesc === ''){reqFields.push('newDateDesc')}
		}
		if(type === 'locker'){
			if(!this.state.newLockerDesc || this.state.newLockerDesc === ''){reqFields.push('newLockerDesc')}
			if(!this.state.newLockerSupplier || this.state.newLockerSupplier === ''){reqFields.push('newLockerSupplier')}
			if(!this.state.newLockerState || this.state.newLockerState === ''){reqFields.push('newLockerState')}
			if(!this.state.newLockerAmount || this.state.newLockerAmount === 0){reqFields.push('newLockerAmount')}
			if(!this.state.newLockerPrice || this.state.newLockerPrice === '' || this.state.newLockerPrice === '0,00' || this.state.newLockerPrice === '0'){reqFields.push('newLockerPrice')}
		}
		if(type === 'keynius'){
			if(!this.state.keyniusMail || this.state.keyniusMail === ''){reqFields.push('keyniusMail')}
			if(!this.state.keyniusPass || this.state.keyniusPass === ''){reqFields.push('keyniusPass')}
		}
		if(reqFields.length > 0){
			this.setState({reqFields: reqFields});
			return false;
		}
		return true;
	}

	// ----------------------------------------------------------------
	// -------------------------- lockers lists------------------------
	// ----------------------------------------------------------------

	importLockerList(event) {
		var kluizenObj = {};
		var reader = new FileReader();	
		if(!event.target.files[0]){return '';}
		if(event.target.files[0].name.slice(-3) === 'lsx'){		
			var [file] = event.target.files;
			reader.onload = (e) => {
				const bstr = e.target.result;
				const wb = XLSX.read(bstr, { type: "binary" });
				const wsname = wb.SheetNames[0];
				const ws = wb.Sheets[wsname];
				const kluisArr = XLSX.utils.sheet_to_csv(ws, { header: 1 }).split('\n');
				kluisArr.forEach(kluis => {
					if(kluis.split(",").length > 2){kluis = kluis.replace(',','.')}
					var kluisNummer = kluis.split(',')[0].replace(/\"/g, "");
					var kluisCode = kluis.substr(kluis.indexOf(',') + 1);
					if(kluisNummer.length > 0 && !isNaN(kluisNummer) && kluisCode.length > 0 && !isNaN(kluisCode)){
						kluizenObj[kluisNummer.replace(".", "")] = {num: kluisNummer, code: kluisCode, beschikbaar: true }
					}
				})			
				this.setState({currKluizen: kluizenObj});
				this.getLockerRef().set({'lockers': kluizenObj},{merge: true})
			};
			reader.readAsBinaryString(file);
		}
		else if(event.target.files[0].name.slice(-3) === 'csv'){
			reader.onload = (e) => {
				const kluisArr = reader.result.split('\n');
				kluisArr.forEach(kluis => {
					var kluisNummer = kluis.split(',')[0];
					var kluisCode = kluis.substr(kluis.indexOf(',') + 1);
					if(kluisNummer.length > 0 && !isNaN(kluisNummer) && kluisCode.length > 0 && !isNaN(kluisCode)){
						kluizenObj[kluisNummer.replace(".", "")] = {num: kluisNummer, code: kluisCode, beschikbaar: true }
					}
				})
				this.setState({currKluizen: kluizenObj});
				this.getLockerRef().set({'lockers': kluizenObj},{merge: true})
			}
			reader.readAsText(event.target.files[0]);
		}
		else{
			alert("Upload een excel of csv bestand");
		}
	}
	
	exportCsv(){
		var lockersCsv = '';
		Object.values(this.state.currKluizen).forEach(locker => {
			if(this.state.newLockerSupplier === 'partylockers'){lockersCsv = lockersCsv + locker + "\r\n"}
			else{lockersCsv = lockersCsv + locker.num + ',' + locker.code + "\r\n"}
		});
		const blob = new Blob([lockersCsv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'export_lockers';
        a.click();
        a.remove();
	}

	delKluis = (num) => {
		var lockerObj = JSON.parse(JSON.stringify(this.state.currKluizen));
		delete lockerObj[num.replace(".", "")];
		this.getLockerRef().set({'lockers': lockerObj});
		this.setState({currKluizen: lockerObj});
	}

	emptyKluizen = () => {
		this.getLockerRef().delete();
		this.setState({currKluizen: {}});
	}

	getLockerRef = () => {
		var lockerID = this.state.editLockerInd >= 0 ? this.getLockerDates()[this.state.currentDate].lockers[this.state.editLockerInd].id : this.state.newLockerId;
		return db.collection("events").doc(this.props.observableStore.currEvent.ID).collection("lockers").doc(lockerID);
	}

	getCodes = () => {this.getLockerRef().get().then(doc=>this.setState({loadingLockerCodes: false, currKluizen: doc.exists && doc.data().lockers ? doc.data().lockers : []}))}
	
	getStats = () => {
		var statsRef = rtdb.ref('/stats/' + this.props.observableStore.uid + '/' + this.props.observableStore.currEvent.ID + '/lockers/' + this.state.newLockerId)
		statsRef.once('value', (snap) => this.setState({loadingLockerStats: false, lockersSold: snap.val() ? snap.val() : 0}));
	}


}

export default Menu;


const SortableLockerList = SortableContainer(({items, editLocker, delLocker}) => {
	return (
		<Table className="v-middle" responsive style={{marginBottom: 0, borderWidth: 0}}>
			<thead>
				<tr>
					<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Omschrijving</td>
					<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Leverancier</td>
					<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Aantal</td>
					<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Prijs</td>
					<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Status</td>
				</tr>
			</thead>
			<tbody>
				{items.map((value, index) => (<SortableLocker key={index} ind={index} editLocker={editLocker} delLocker={delLocker} value={value} />))}
			</tbody>
		</Table>
	);
});

const SortableLocker = SortableElement(({value, ind, editLocker, delLocker}) => 
	<tr key={ind} style={{opacity: value.hidden ? 0.5 : 1, background: ind%2 === 0 ? null : 'rgba(0,0,0,0.03)'}}>
		<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{value.desc}</h6></td>
		<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{value.supplier}</h6></td>
		<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{value.amount}</h6></td>
		<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>€ {value.price}</h6></td>
		<td style={{fontSize: 18, width: '20%', padding: 0, textAlign: 'right'}}><h6 style={{width: 125, fontWeight: 500, background: value.state === 'Beschikbaar' ? '#43A047' : '#e53935', textAlign: 'center', borderRadius: 5, color: '#fff', padding: 6, paddingTop: 8}}>{value.state}</h6></td>
		<td style={{fontSize: 18, minWidth: 120, padding: 0, textAlign: 'right'}}>
			<i class="fa fa-edit hightlightIcon2" onClick={() => editLocker(ind)} style={{cursor:'pointer'}}></i>
			<i class="fa fa-trash hightlightIcon2" onClick={() => delLocker(ind)} style={{cursor:'pointer', marginLeft: '15%',}}></i>
			<DragHandle />
		</td>
	</tr>
);

const DragHandle = sortableHandle(() => <i class="fa-regular fa-arrows-alt hightlightIcon2" style={{marginLeft: '15%', marginRight: '10%', cursor:'pointer'}}></i>);