import React from 'react';
import arrayMove from 'array-move';
import { Table, Row, Col, Form, FormGroup, Modal, Label, ModalHeader, ModalBody, ModalFooter, Input, InputGroup, InputGroupAddon, InputGroupText, Button,} from 'reactstrap';
import { storage, functionsEU } from '../components/firebase/firebase';
import {SortableContainer, SortableElement, sortableHandle} from 'react-sortable-hoc';
import { SketchPicker } from 'react-color'
import { observer, inject } from 'mobx-react';
import tempImg from '../assets/images/tempLogo.png'
import examplePhone from '../assets/images/examplephone.png'
import Datetime from 'react-datetime';
import moment from "moment";
import Cropper from 'react-easy-crop';
import PasswordStrengthBar from 'react-password-strength-bar';
import { v4 as uuidv4 } from 'uuid';
import "moment/locale/nl";

@inject('observableStore')
@observer

class Instellingen extends React.Component {	
	render() {
    return(
		<div style={{paddingLeft: 10, paddingRight: 10, paddingBottom: 100}}>
			<Row>
				<Col lg={12}>
					<h2 className="font-light text-muted topTitle" style={{fontSize: 28, fontWeight: 900, color: '#212529'}}>Instellingen</h2>
				</Col>
			</Row>
			<Row style={{marginTop: 40, paddingLeft: 15, paddingRight: 15}}>
				<Col lg={2} className="graph-display" style={{background: 'none', paddingTop: 0, paddingRight: 0, paddingLeft: 0, minWidth: 240}}>
					<div className="settingsnav" style={{backgroundColor: this.state.settingsCat === 'info' ? '#f6f6f6' : '', borderLeftWidth: this.state.settingsCat === 'info' ? 6 : 0}} onClick={() => this.setState({settingsCat: 'info'})}>
						<span>Informatie</span>
					</div>
					<div className="settingsnav" style={{backgroundColor: this.state.settingsCat === 'tokens' ? '#f6f6f6' : '', borderLeftWidth: this.state.settingsCat === 'tokens' ? 6 : 0}} onClick={() => this.setState({settingsCat: 'tokens'})}>
						<span>Muntjes</span>
					</div>
					<div className="settingsnav" style={{backgroundColor: this.state.settingsCat === 'transacties' ? '#f6f6f6' : '', borderLeftWidth: this.state.settingsCat === 'transacties' ? 6 : 0}} onClick={() => this.setState({settingsCat: 'transacties'})}>
						<span>Transacties</span>
					</div>
					<div className="settingsnav" style={{backgroundColor: this.state.settingsCat === 'paymentmethods' ? '#f6f6f6' : '', borderLeftWidth: this.state.settingsCat === 'paymentmethods' ? 6 : 0}} onClick={() => this.setState({settingsCat: 'paymentmethods'})}>
						<span>Betaalmethodes</span>
					</div>
					<div className="settingsnav" style={{backgroundColor: this.state.settingsCat === 'tickets' ? '#f6f6f6' : '', borderLeftWidth: this.state.settingsCat === 'tickets' ? 6 : 0}} onClick={() => this.setState({settingsCat: 'tickets'})}>
						<span>Ticketshops</span>
					</div>
					<div className="settingsnav" style={{backgroundColor: this.state.settingsCat === 'timetable' ? '#f6f6f6' : '', borderLeftWidth: this.state.settingsCat === 'timetable' ? 6 : 0}} onClick={() => this.setState({settingsCat: 'timetable'})}>
						<span>Timetable</span>
					</div>
					<div className="settingsnav" style={{backgroundColor: this.state.settingsCat === 'hardcups' ? '#f6f6f6' : '', borderLeftWidth: this.state.settingsCat === 'hardcups' ? 6 : 0}} onClick={() => this.setState({settingsCat: 'hardcups'})}>
						<span>Statiegeld</span>
					</div>
					<div className="settingsnav" style={{backgroundColor: this.state.settingsCat === 'koptelefoons' ? '#f6f6f6' : '', borderLeftWidth: this.state.settingsCat === 'koptelefoons' ? 6 : 0}} onClick={() => this.setState({settingsCat: 'koptelefoons'})}>
						<span>Koptelefoons</span>
					</div>
					<div className="settingsnav" style={{backgroundColor: this.state.settingsCat === 'betaalkaarten' ? '#f6f6f6' : '', borderLeftWidth: this.state.settingsCat === 'betaalkaarten' ? 6 : 0}} onClick={() => this.setState({settingsCat: 'betaalkaarten'})}>
						<span>Betaalkaarten</span>
					</div>
					<div className="settingsnav" style={{backgroundColor: this.state.settingsCat === 'integraties' ? '#f6f6f6' : '', borderLeftWidth: this.state.settingsCat === 'integraties' ? 6 : 0}} onClick={() => this.setState({settingsCat: 'integraties'})}>
						<span>Integraties</span>
					</div>
					<div className="settingsnav" style={{backgroundColor: this.state.settingsCat === 'organisatie' ? '#f6f6f6' : '', borderLeftWidth: this.state.settingsCat === 'organisatie' ? 6 : 0}} onClick={() => this.setState({settingsCat: 'organisatie'})}>
						<span>Organisatie</span>
					</div>
					<div className="settingsnav" style={{backgroundColor: this.state.settingsCat === 'account' ? '#f6f6f6' : '', borderLeftWidth: this.state.settingsCat === 'account' ? 6 : 0}} onClick={() => this.setState({settingsCat: 'account'})}>
						<span>Account</span>
					</div>
				</Col>
				
				
				<Col className="graph-display graph-menu graph-scroll" style={{marginLeft: 20, padding: 40, paddingTop: 30, overflow: 'visible'}}>					
					
					{this.state.settingsCat === 'info' ?
					<Form className="mt-1">
						<h2 className="font-light text-muted" style={{marginTop: 10, fontSize: 20, fontWeight: 900, color: '#212529',}}>Informatie</h2>
						<div className="addBtnLst addBtnLstSettings" style={{cursor: 'pointer', position: 'absolute', top: 20, right: 35, width: 150, background: '#d12a5e', color: '#fff', }} onClick={() => this.saveEventInfo('settings')}>Opslaan</div>
						<div style={{width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)', marginTop: 20, marginBottom: 20}}></div>
						<Row>
							<Col lg={5}>
								<div style={{maxWidth: 450}}>
									<div className="switchBtn" style={{marginTop: 10}}>
										<label htmlFor="staticEmail" className="col-form-label" style={{marginTop: 2}}>{this.props.observableStore.currEvent.actief ? 'Zichtbaar in evenementenlijst' : 'Onzichtbaar in evenementenlijst'}</label>
										<div style={{cursor: 'pointer', height: 29, width: 55, padding: 3, backgroundColor: this.props.observableStore.currEvent.actief ? '#d12a5e' : '#ccc', borderRadius: 25}} onClick={ () => {this.toggleActief()}}>
											<div style={{height: 23, width: 23, borderRadius: 12, backgroundColor: '#fff', float: this.props.observableStore.currEvent.actief ? 'right' : 'left'}}></div>
										</div>
									</div>
									<div style={{display: 'flex', marginTop: 20, overflow: 'hidden', height: 44, borderRadius: 10, borderWidth: 1, borderStyle: 'solid', borderColor: '#d9d9d9', cursor: 'pointer'}}>
										<div style={{paddingLeft: 8, paddingTop: 11, width: '50%', textAlign: 'center', backgroundColor: this.props.observableStore.currEvent.type === 'event' ? '#f2f2f2' : '', color: this.props.observableStore.currEvent.type === 'event' ? '#212529' : 'rgba(0,0,0,0.4)', borderStyle: 'solid', borderWidth: 0, borderRightWidth: 1, borderColor: 'rgb(217, 217, 217)'}} onClick={() => this.setEventType('event')}>Evenement</div>
										<div style={{padding: 8, paddingTop: 11, width: '50%', textAlign: 'center', backgroundColor: this.props.observableStore.currEvent.type === 'locatie' ? '#f2f2f2' : '', color: this.props.observableStore.currEvent.type === 'locatie' ? '#212529' : 'rgba(0,0,0,0.4)'}} onClick={() => this.setEventType('locatie')}>Locatie</div>
									</div>
									<h2 className="font-light text-muted" style={{fontSize: 18, color: '#212529',marginTop: 30}}>Cover afbeelding</h2>
									<div style={{width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)', marginTop: 15}}></div>
									<div style={{position: 'relative', marginTop: 20}}>
										<div onClick={() => this.imgUpload.click()} style={{cursor: 'pointer'}}>
											{this.state.uploadState ?
											<div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 40, paddingBottom: 40}}>
												<div class="loading-spinner" style={{width: 35, height: 35, borderWidth: 4}}></div>
											</div>
											:
											<img src={!this.props.observableStore.currEvent.cover ? tempImg : this.props.observableStore.currEvent.cover} style={{width: '100%', resizeMode: 'contain', marginBottom: 6, borderRadius: 5,}} alt='evenement logo'/>
											}
											<div style={{position: 'absolute', borderTopLeftRadius: 50, bottom: 0, right: 0, width: 50, height: 50, backgroundColor: '#f6f6f6'}}>
												<i class="fa fa-edit" style={{fontSize: 19, marginLeft: 27, marginTop: 20}}></i>
											</div>
											<FormGroup className="mb-1" style={{display: 'none'}}>			
												<div className="custom-file" style={{marginTop: 25}}>
													<Input innerRef={input => this.imgUpload = input} type="file" onChange={e => this.loadImg(e)} />
												</div>
											</FormGroup>
										</div>
									</div>
									<label className="col-form-label" style={{fontSize: 13}}>Upload een jpg of png afbeelding</label>	
									<h2 className="font-light text-muted" style={{fontSize: 18, color: '#212529',marginTop: 25}}>Evenement details</h2>
									<div style={{width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)', marginTop: 15}}></div>
									<FormGroup style={{marginTop: 20, marginBottom: 20}}>
										<InputGroup>
											<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
												<InputGroupText style={{background: '#fff', width: 120, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>Naam</InputGroupText>
											</InputGroupAddon>
											<input style={{height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10}}  type="text" className="form-control" value={this.props.observableStore.currEvent.naam} onChange={e => this.props.observableStore.currEvent.naam = e.target.value} />
										</InputGroup>
									</FormGroup>								
									<FormGroup style={{marginBottom: 20}}>
										<InputGroup>
											<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
												<InputGroupText style={{background: '#fff', width: 120, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>Locatie</InputGroupText>
											</InputGroupAddon>
											<input style={{height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10}} type="text" className="form-control"  value={this.props.observableStore.currEvent.locatie} onChange={e => this.props.observableStore.currEvent.locatie = e.target.value}/>
										</InputGroup>
									</FormGroup>
									<FormGroup style={{marginBottom: 20}}>
										<InputGroup>
											<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
												<InputGroupText style={{background: '#fff', width: 120, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>Capaciteit</InputGroupText>
											</InputGroupAddon>
											<Input style={{height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10}}  type="number"  value={this.props.observableStore.currEvent.capaciteit} onChange={e => this.props.observableStore.currEvent.capaciteit = e.target.value}/>
										</InputGroup>
									</FormGroup>
									{this.props.observableStore.currEvent.type === 'event' ?
									<div style={{marginTop: 20}}>
										<div style={{display: 'flex', alignItems: 'center',borderRadius: 10, borderStyle: 'solid', borderWidth: 1, borderColor: 'rgb(217, 217, 217)', overflow: 'hidden'}}>
											<div style={{position: 'absolute', left: 132}}><Datetime timeFormat={false} className="dateSettings" value={this.getTime('event')} onChange={e => this.setDate(e)} closeOnSelect={true} dateFormat="DD-MM-YYYY" locale="nl"/></div>
											<div style={{display: 'flex', alignItems: 'center', width: 120, paddingLeft: 12, height: 44, backgroundColor: '#f2f2f2', borderStyle: 'solid', borderWidth: 0, borderRightWidth: 1, borderColor: 'rgb(217, 217, 217)'}}>
												<label htmlFor="staticEmail" className="col-form-label" style={{color: '#525f7f'}}>Startdatum</label>
											</div>
										</div>										
										<FormGroup style={{marginTop: 20}}>
											<InputGroup>
												<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
													<InputGroupText style={{background: '#fff', width: 120, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>Aantal dagen</InputGroupText>
												</InputGroupAddon>
												<Input type="number" style={{height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10}} className="FullColorPlaceholder" placeholder="0" value={this.props.observableStore.currEvent.dagen} onChange={e => this.props.observableStore.currEvent.dagen = parseFloat(e.target.value)}/>
											</InputGroup>
										</FormGroup>
									</div>
									:null}
									<h2 className="font-light text-muted" style={{fontSize: 18, color: '#212529',marginTop: 30}}>Plattegrond</h2>
									<div style={{width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)', marginTop: 15}}></div>
									<div style={{position: 'relative', marginTop: 20}}>
										{this.state.uploadMapState ?
										<div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 40, paddingBottom: 40}}>
											<div class="loading-spinner" style={{width: 35, height: 35, borderWidth: 4}}></div>
										</div>
										:
										<img src={!this.props.observableStore.currEvent.festivalmap ? tempImg : this.props.observableStore.currEvent.festivalmap} style={{width: '100%', resizeMode: 'contain', marginBottom: 6, borderRadius: 5,}} alt='evenement plattegrond'/>
										}
										<div onClick={() => this.imgUpload2.click()} style={{position: 'absolute', cursor: 'pointer', borderTopLeftRadius: 50, bottom: 0, right: 0, width: 50, height: 50, backgroundColor: '#f6f6f6'}}>
											<i class="fa fa-edit" style={{fontSize: 19, marginLeft: 27, marginTop: 20}}></i>
										</div>
										<FormGroup className="mb-1" style={{display: 'none'}}>			
											<div className="custom-file" style={{marginTop: 25}}>
												<Input innerRef={input => this.imgUpload2 = input} type="file" className="custom-file-input" id="customFile1" onChange={e => this.saveMap(e, true)} />
												<label className="custom-file-label" htmlFor="customFile1">Kies een bestand</label>
											</div>
										</FormGroup>
									</div>
								</div>
							</Col>
							<Col lg={7} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
								<div style={{position: 'relative', marginBottom: 300, width: 300, overflow: 'hidden'}}>
									<div style={{position: 'absolute', zIndex: 7, width: 240, overflow: 'hidden',  marginLeft: 27, marginTop: 165, whiteSpace: 'nowrap'}}>
										<h2 className="font-light text-muted" style={{fontSize: 15, color: '#212529', fontWeight: 900}}>{this.props.observableStore.currEvent.naam}</h2>
										<h2 className="font-light text-muted" style={{marginTop: -6, fontSize: 10, color: 'rgba(0,0,0,0.5)'}}>{this.props.observableStore.currEvent.locatie}</h2>
									</div>
									<img style={{position: 'absolute', width: 264, marginTop: 18, marginLeft: 15, zIndex: 5, resizeMode: 'contain', marginBottom: 6, borderRadius: 5,}} src={!this.props.observableStore.currEvent.cover ? tempImg : this.props.observableStore.currEvent.cover} alt='evenement logo'/>
									<img src={examplePhone} style={{position: 'relative', zIndex: 6, width: 300, resizeMode: 'contain'}} alt='voorbeeld'/>
								</div>
							</Col>
						</Row>
					</Form>
					:null}
					
					{this.state.settingsCat === 'tokens' ?
					<Form className="mt-1">
						<h2 className="font-light text-muted" style={{marginTop: 10, fontSize: 20, fontWeight: 900, color: '#212529',}}>Muntjes</h2>
						<div className="addBtnLst addBtnLstSettings" style={{cursor: 'pointer', position: 'absolute', top: 20, right: 35, width: 150, background: '#d12a5e', color: '#fff', }} onClick={() => this.saveEventInfo('settings')}>Opslaan</div>
						<div style={{width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)', marginTop: 20, marginBottom: 30}}></div>
						<Row>
							<Col lg={5}>
								<div style={{maxWidth: 350}}>
									<h2 className="font-light text-muted" style={{fontSize: 18, color: '#212529',}}>Verkoop</h2>
									<div style={{width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)', marginTop: 15, marginBottom: 20}}></div>
									<div className="switchBtn">
										<label htmlFor="staticEmail" className="col-form-label" style={{marginTop: 2}}>Muntverkoop {this.props.observableStore.currEvent.tokenverkoop ? 'aan' : 'uit'}</label>
										<div style={{cursor: 'pointer', height: 29, width: 55, padding: 3, backgroundColor: this.props.observableStore.currEvent.tokenverkoop ? '#d12a5e' : '#ccc', borderRadius: 25}} onClick={ () => {this.props.observableStore.currEvent.tokenverkoop = !this.props.observableStore.currEvent.tokenverkoop}}>
											<div style={{height: 23, width: 23, borderRadius: 12, backgroundColor: '#fff', float: this.props.observableStore.currEvent.tokenverkoop ? 'right' : 'left'}}></div>
										</div>
									</div>
									<div className="switchBtn">
										<label htmlFor="staticEmail" className="col-form-label" style={{marginTop: 2}}>Op krediet bestellen</label>
										<div style={{cursor: 'pointer', height: 29, width: 55, padding: 3, backgroundColor: this.props.observableStore.currEvent.krediet ? '#d12a5e' : '#ccc', borderRadius: 25}} onClick={ () => {this.props.observableStore.currEvent.krediet = !this.props.observableStore.currEvent.krediet}}>
											<div style={{height: 23, width: 23, borderRadius: 12, backgroundColor: '#fff', float: this.props.observableStore.currEvent.krediet ? 'right' : 'left'}}></div>
										</div>
									</div>
									<FormGroup style={{marginBottom: 20}}>
										<InputGroup>
											<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
												<InputGroupText style={{background: '#fff', width: 150, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>Prijs muntje</InputGroupText>
											</InputGroupAddon>
											<InputGroupAddon addonType="prepend" style={{height: 44}}>
												<InputGroupText style={{background: '#fff'}}><i class="fa fa-euro-sign" style={{marginRight: -4}}></i></InputGroupText>
											</InputGroupAddon>
											<Input style={{borderLeftWidth: 0, paddingLeft: 0, height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10}} type="text" className="FullColorPlaceholder" placeholder="0,00" value={this.props.observableStore.currEvent.muntprijs.replace(".",",")} onChange={e => this.props.observableStore.currEvent.muntprijs = e.target.value}/>
										</InputGroup>
									</FormGroup>
									<FormGroup style={{marginBottom: 20}}>
										<InputGroup>
											<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
												<InputGroupText style={{background: '#fff', width: 150, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>Min koop aantal</InputGroupText>
											</InputGroupAddon>
											<InputGroupAddon addonType="prepend" style={{height: 44}}>
												<InputGroupText style={{background: '#fff'}}></InputGroupText>
											</InputGroupAddon>
											<InputGroupAddon addonType="prepend" style={{height: 44}}></InputGroupAddon>
											<Input type="number" style={{borderLeftWidth: 0, paddingLeft: 0, height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10}} value={this.props.observableStore.currEvent.muntmin} className="FullColorPlaceholder" placeholder="1" onChange={e => this.props.observableStore.currEvent.muntmin = parseFloat(e.target.value)}/>
										</InputGroup>
									</FormGroup>
									<div style={{width: '100%', display: 'flex', justifyContent: 'center'}}><div className="fixedAmountSetup" onClick={() => this.toggleFixedAmountModal()}>Vaste aantallen instellen</div></div>
									<h2 className="font-light text-muted" style={{marginTop: 35, fontSize: 18, color: '#212529',}}>Retourneren</h2>
									<div style={{width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)', marginTop: 15, marginBottom: 20}}></div>
									<div className="switchBtn">
										<label htmlFor="staticEmail" className="col-form-label" style={{marginTop: 2}}>Retourneren toestaan</label>
										<div style={{cursor: 'pointer', height: 29, width: 55, padding: 3, backgroundColor: this.props.observableStore.currEvent.muntretour.allowed ? '#d12a5e' : '#ccc', borderRadius: 25}} onClick={() => {this.toggletokenretour()}}>
											<div style={{height: 23, width: 23, borderRadius: 12, backgroundColor: '#fff', float: this.props.observableStore.currEvent.muntretour.allowed ? 'right' : 'left'}}></div>
										</div>
									</div>
									<FormGroup>
										<Input type="select" style={{marginTop: 20, width: '100%', height: 42, borderRadius: 10}} className='minimal' onChange={e => {this.props.observableStore.currEvent.muntretour.verval = e.target.value}}>
											<option value="" selected={this.props.observableStore.currEvent.muntretour.verval === ''}>Retourneren vervalt niet</option>
											<option value="periode" selected={this.props.observableStore.currEvent.muntretour.verval === 'periode'}>Vervalt binnen bepaalde periode</option>
											<option value="datum" selected={this.props.observableStore.currEvent.muntretour.verval === 'datum'}>Vervalt na bepaalde datum</option>
										</Input>
									</FormGroup>
									{this.props.observableStore.currEvent.muntretour.verval === 'periode' ?
									<FormGroup style={{marginTop: 20, marginBottom: 20}}>
										<InputGroup>
											<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
												<InputGroupText style={{background: '#fff', width: 140, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>Aantal dagen</InputGroupText>
											</InputGroupAddon>
											<Input type="number" style={{height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10}} className="FullColorPlaceholder" placeholder="0" value={this.props.observableStore.currEvent.muntretour.periode} onChange={e => this.props.observableStore.currEvent.muntretour.periode = parseFloat(e.target.value)}/>
										</InputGroup>
									</FormGroup>
									:null}
									{this.props.observableStore.currEvent.muntretour.verval === 'datum' ?
									<div style={{marginTop: 20, marginBottom: 20, height: 44, display: 'flex', alignItems: 'center',borderRadius: 10, borderStyle: 'solid', borderWidth: 1, borderColor: 'rgb(217, 217, 217)', overflow: 'hidden'}}>
										<div style={{position: 'absolute', left: 150}}><Datetime timeFormat="HH:mm" className="dateSettings" value={this.getRetourDate()} onChange={e => this.setRetourDate(e)} closeOnSelect={true} dateFormat="DD-MM-YYYY" locale="nl"/></div>
										<div style={{display: 'flex', alignItems: 'center', width: 140, paddingLeft: 12, height: 44, backgroundColor: '#f2f2f2', borderStyle: 'solid', borderWidth: 0, borderRightWidth: 1, borderColor: 'rgb(217, 217, 217)'}}>
											<label htmlFor="staticEmail" className="col-form-label" style={{color: '#525f7f'}}>Tot en met</label>
										</div>
									</div>	
									:null}
									{this.props.observableStore.isAdmin ? <div className="ticketshopAdd" style={{width: '100%', paddingLeft: 10, paddingRight: 10, alignItems: 'center', justifyContent: 'center'}} onClick={() => this.toggleRefundAllModal()}>Retourneer alle openstaande muntjes</div>:null}
									<h2 className="font-light text-muted" style={{marginTop: 35, fontSize: 18, color: '#212529',}}>Delen</h2>
									<div style={{width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)', marginTop: 15, marginBottom: 20}}></div>
									<div className="switchBtn">
										<label htmlFor="staticEmail" className="col-form-label" style={{marginTop: 2}}>Delen uitschakelen</label>
										<div style={{cursor: 'pointer', height: 29, width: 55, padding: 3, backgroundColor: this.props.observableStore.currEvent.preventShare ? '#d12a5e' : '#ccc', borderRadius: 25}} onClick={() => {this.props.observableStore.currEvent.preventShare = !this.props.observableStore.currEvent.preventShare}}>
											<div style={{height: 23, width: 23, borderRadius: 12, backgroundColor: '#fff', float: this.props.observableStore.currEvent.preventShare ? 'right' : 'left'}}></div>
										</div>
									</div>
								</div>
							</Col>
							<Col lg={6} style={{paddingLeft: 50, paddingRight: 50}} className="explainCol">
								<label style={{marginTop: 20}} htmlFor="inputPassword" className="col-form-label">Wanneer "op krediet bestellen" aan staat kunnen bestellingen in het geval van een internet storing toch doorgaan. De saldo wijziging wordt doorgevoerd zodra het internet terug komt. Dit is alleen mogelijk voor bezoekers die via Ideal, Bancontact of Sofort hebben betaald. Zo kan een mogelijk negatief saldo worden geïncasseerd via SEPA Direct Debit.</label>
								<label style={{marginTop: 20}} htmlFor="inputPassword" className="col-form-label">Wanneer je retourneren toestaat kan de bezoeker het geld voor een of meerdere niet uitgegeven muntjes terugvragen. Het aantal muntjes mag niet meer zijn dan het aantal aangeschafte muntjes bij de laatste transactie.</label>
								<label style={{marginTop: 20}} htmlFor="inputPassword" className="col-form-label">De retourperiode is het aantal dagen na de laatste transactie waarin een gebruiker nog is toegestaan om te retourneren. Bij een vervaldatum kan de bezoeker zijn muntjes terugsturen tot en met de opgegeven datum.</label>
							</Col>
						</Row>
					</Form>
					:null}	

					{this.state.settingsCat === 'tickets' ?
					<Form className="mt-1">
						<h2 className="font-light text-muted" style={{marginTop: 10, fontSize: 20, fontWeight: 900, color: '#212529',}}>Ticketshops</h2>
						<div className="addBtnLst addBtnLstSettings" style={{cursor: 'pointer', position: 'absolute', top: 20, right: 35, width: 150, background: '#d12a5e', color: '#fff', }} onClick={() => this.saveEventInfo('settings')}>Opslaan</div>
						<div style={{width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)', marginTop: 20, marginBottom: 30}}></div>
						<Row>
							<Col lg={5}>
								<div style={{maxWidth: 350}}>
									{this.props.observableStore.currEvent.ticketshops ? this.props.observableStore.currEvent.ticketshops.map((shop, index) => (
									<div className="ticketshopContainer">
										<div className="ticketshopDel" onClick={() => this.delTicketshop(index)}><i class="fa-regular fa-trash refreshBtnI" style={{color: '#525f7f'}}></i></div>
										<h2 className="font-light text-muted" style={{fontSize: 15, color: '#212529', fontWeight: 900}}>Naam</h2>
										<h2 className="font-light text-muted" style={{fontSize: 12, color: 'rgba(0,0,0,0.5)', fontWeight: 900}}>{shop.naam}</h2>
										<h2 className="font-light text-muted" style={{fontSize: 15, marginTop: 10, color: '#212529', fontWeight: 900}}>Provider</h2>
										<h2 className="font-light text-muted" style={{fontSize: 12, color: 'rgba(0,0,0,0.5)', fontWeight: 900}}>{shop.provider ? (shop.provider.charAt(0).toUpperCase() + shop.provider.slice(1)) : ''}</h2>
										<h2 className="font-light text-muted" style={{fontSize: 15, marginTop: 10, color: '#212529', fontWeight: 900}}>Url</h2>
										<h2 className="font-light text-muted" style={{width: 200, wordBreak: 'break-all', fontSize: 12, color: 'rgba(0,0,0,0.5)'}}>{shop.url}</h2>
										<div style={{width: '100%', display: 'flex', marginTop: 10, justifyContent: 'center'}}><div className="settingsListBtn" onClick={() => window.open(shop.url)}>Ga naar ticketshop</div></div>
									</div>
									)):null}
									<div style={{width: '100%', display: 'flex', justifyContent: 'center'}}><div className="ticketshopAdd" onClick={() => this.toggleTicketShopModal()}>Voeg een ticketshop toe</div></div>
								</div>
							</Col>
							<Col lg={6} style={{paddingLeft: 50, paddingRight: 50}} className="explainCol">
								<label style={{marginTop: 15}} htmlFor="inputPassword" className="col-form-label">Voeg een ticketshop toe zodat bezoekers vanuit de app naar jouw ticketshop worden gelinkt. Wij hebben een koppeling met verschillende ticketproviders zodat de tickets van jouw bezoekers in de app kunnen worden opgehaald. <br/><br/>  Neem voor meer informatie over ticket koppelingen contact op met jouw accountmanager.</label>
							</Col>
						</Row>
					</Form>
					:null}		

					{this.state.settingsCat === 'timetable' ?
					<Form className="mt-1">
						<h2 className="font-light text-muted" style={{marginTop: 10, fontSize: 20, fontWeight: 900, color: '#212529',}}>Timetable</h2>
						<div className="addBtnLst addBtnLstSettings" style={{cursor: 'pointer', position: 'absolute', top: 20, right: 35, width: 150, background: '#d12a5e', color: '#fff', }} onClick={() => this.saveEventInfo('settings')}>Opslaan</div>
						<div style={{width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)', marginTop: 20, marginBottom: 30}}></div>
						<Row>
							<Col lg={5}>
								<div style={{maxWidth: 350}}>
									{this.props.observableStore.currEvent.timetable ? this.props.observableStore.currEvent.timetable.map((timetable, index) => (
									<div className="ticketshopContainer">
										<div className="ticketshopDel" onClick={() => this.props.observableStore.currEvent.timetable.splice(index, 1)}><i class="fa-regular fa-trash refreshBtnI" style={{color: '#525f7f'}}></i></div>
										<h2 className="font-light text-muted" style={{fontSize: 18, textAlign: 'center', color: '#212529', fontWeight: 900}}>{this.formatDay(timetable.dag, true)}</h2>
										<h2 className="font-light text-muted" style={{fontSize: 15, marginTop: 5, textAlign: 'center', color: '#d12a5e', fontWeight: 900}}>{this.formatDay(timetable.dag)}</h2>
										<img src={this.props.observableStore.currEvent.timetable[index].img} style={{marginTop: 20, width: '100%', resizeMode: 'contain', marginBottom: 6, borderRadius: 5,}} alt='timetable'/>
									</div>
									)):null}
									<div style={{width: '100%', display: 'flex', justifyContent: 'center'}}><div className="ticketshopAdd" onClick={() => this.toggleTimetableModal()}>Voeg een timetable toe</div></div>
								</div>
							</Col>
							<Col lg={6} style={{paddingLeft: 50, paddingRight: 50}} className="explainCol">
								<label style={{marginTop: 15}} htmlFor="inputPassword" className="col-form-label">Voeg een timetable toe zodat bezoekers een overzicht krijgen van lineup en tijden. Je kunt voor meerdere datums een timetable toevoegen.</label>
							</Col>
						</Row>
					</Form>
					:null}				
					
					{this.state.settingsCat === 'transacties' ?
					<Form className="mt-1">
						<h2 className="font-light text-muted" style={{marginTop: 10, fontSize: 20, fontWeight: 900, color: '#212529',}}>Transacties</h2>
						<div className="addBtnLst addBtnLstSettings" style={{cursor: 'pointer', position: 'absolute', top: 20, right: 35, width: 150, background: '#d12a5e', color: '#fff', }} onClick={() => this.saveEventInfo('settings')}>Opslaan</div>
						<div style={{width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)', marginTop: 20, marginBottom: 30}}></div>
						<Row>
							<Col lg={5}>
								<div style={{maxWidth: 380}}>
									<FormGroup style={{marginBottom: 20}}>
										<InputGroup>
											<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
												<InputGroupText style={{background: '#fff', width: 125, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>MerchantId</InputGroupText>
											</InputGroupAddon>
											<Input type="text" style={{height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10}} value={this.props.observableStore.currEvent.pay.merchantId} placeholder="M-" onChange={e => {this.props.observableStore.currEvent.pay.merchantId = e.target.value}}/>
										</InputGroup>
									</FormGroup>
									<FormGroup style={{marginBottom: 20}}>
										<InputGroup>
											<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
												<InputGroupText style={{background: '#fff', width: 125, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>ServiceId</InputGroupText>
											</InputGroupAddon>
											<Input type="text" style={{height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10}} value={this.props.observableStore.currEvent.pay.serviceId} placeholder="SL-" onChange={e => {this.props.observableStore.currEvent.pay.serviceId = e.target.value}}/>
										</InputGroup>
									</FormGroup>
									<div className="switchBtn">
										<label htmlFor="staticEmail" className="col-form-label">Betalingen accepteren</label>
										<div style={{cursor: 'pointer', height: 29, width: 55, padding: 3, backgroundColor: this.props.observableStore.currEvent.pay.accept ? '#d12a5e' : '#ccc', borderRadius: 25}} onClick={() => {this.props.observableStore.currEvent.pay.accept = !this.props.observableStore.currEvent.pay.accept}}>
											<div style={{height: 23, width: 23, borderRadius: 12, backgroundColor: '#fff', float: this.props.observableStore.currEvent.pay.accept ? 'right' : 'left'}}></div>
										</div>
									</div>	
									<div className="switchBtn">
										<label htmlFor="staticEmail" className="col-form-label">Servicekosten doorbelasten</label>
										<div style={{cursor: 'pointer', height: 29, width: 55, padding: 3, backgroundColor: this.props.observableStore.currEvent.pay.serviceFee.chargeUser ? '#d12a5e' : '#ccc', borderRadius: 25}} onClick={ () => {this.props.observableStore.currEvent.pay.serviceFee.chargeUser = !this.props.observableStore.currEvent.pay.serviceFee.chargeUser}}>
											<div style={{height: 23, width: 23, borderRadius: 12, backgroundColor: '#fff', float: this.props.observableStore.currEvent.pay.serviceFee.chargeUser ? 'right' : 'left'}}></div>
										</div>
									</div>
									<div className="switchBtn">
										<label htmlFor="staticEmail" className="col-form-label">Uitbetaling spreiden<i class="fa fa-info-circle serviceInfoIcon" style={{position: 'relative', color: 'rgba(0,0,0,0.5)', marginLeft: 5, marginTop: 2}}><div className="serviceInfoContainer"><div className="serviceInfoText">Wanneer je kiest voor 'Uitbetaling spreiden' wordt elke dag 80% van het boeksaldo overgemaakt. Zodra het boeksaldo onder €50,00 komt wordt het volledige resterende boeksaldo overgemaakt. Zo blijft er de eerste paar dagen na een evenementdag een gedeelte van het boeksaldo staan voor bezoekers die munten willen retourneren.</div></div></i></label>
										<div style={{cursor: 'pointer', height: 29, width: 55, padding: 3, backgroundColor: this.props.observableStore.currEvent.clearing ? '#d12a5e' : '#ccc', borderRadius: 25}} onClick={ () => {this.props.observableStore.currEvent.clearing = !this.props.observableStore.currEvent.clearing}}>
											<div style={{height: 23, width: 23, borderRadius: 12, backgroundColor: '#fff', float: this.props.observableStore.currEvent.clearing ? 'right' : 'left'}}></div>
										</div>
									</div>
									{this.props.observableStore.isAdmin ?
									<div>
										<div className="switchBtn">
											<label htmlFor="staticEmail" className="col-form-label">Identificatie via IDIN</label>
											<div style={{cursor: 'pointer', height: 29, width: 55, padding: 3, backgroundColor: this.props.observableStore.currEvent.idin ? '#d12a5e' : '#ccc', borderRadius: 25}} onClick={ () => {this.props.observableStore.currEvent.idin = !this.props.observableStore.currEvent.idin}}>
												<div style={{height: 23, width: 23, borderRadius: 12, backgroundColor: '#fff', float: this.props.observableStore.currEvent.idin ? 'right' : 'left'}}></div>
											</div>
										</div>	
										<div className="switchBtn">
											<label htmlFor="staticEmail" className="col-form-label">Betaling via fallback</label>
											<div style={{cursor: 'pointer', height: 29, width: 55, padding: 3, backgroundColor: this.props.observableStore.currEvent.fallbackPay ? '#d12a5e' : '#ccc', borderRadius: 25}} onClick={ () => {this.props.observableStore.currEvent.fallbackPay = !this.props.observableStore.currEvent.fallbackPay}}>
												<div style={{height: 23, width: 23, borderRadius: 12, backgroundColor: '#fff', float: this.props.observableStore.currEvent.fallbackPay ? 'right' : 'left'}}></div>
											</div>
										</div>	
										<div className="switchBtn">
											<label htmlFor="staticEmail" className="col-form-label">Betaling via Mollie</label>
											<div style={{cursor: 'pointer', height: 29, width: 55, padding: 3, backgroundColor: this.props.observableStore.currEvent.fallbackMollie ? '#d12a5e' : '#ccc', borderRadius: 25}} onClick={ () => {this.props.observableStore.currEvent.fallbackMollie = !this.props.observableStore.currEvent.fallbackMollie}}>
												<div style={{height: 23, width: 23, borderRadius: 12, backgroundColor: '#fff', float: this.props.observableStore.currEvent.fallbackMollie ? 'right' : 'left'}}></div>
											</div>
										</div>	
										<div className="switchBtn">
											<label htmlFor="staticEmail" className="col-form-label">Appstore review</label>
											<div style={{cursor: 'pointer', height: 29, width: 55, padding: 3, backgroundColor: this.props.observableStore.currEvent.review ? '#d12a5e' : '#ccc', borderRadius: 25}} onClick={ () => {this.props.observableStore.currEvent.review = !this.props.observableStore.currEvent.review}}>
												<div style={{height: 23, width: 23, borderRadius: 12, backgroundColor: '#fff', float: this.props.observableStore.currEvent.review ? 'right' : 'left'}}></div>
											</div>
										</div>	
									</div>
									:null}									
								</div>
							</Col>
							<Col lg={6} style={{paddingLeft: 50, paddingRight: 50}} className="explainCol">
								<p style={{fontSize: 17, color: '#4a4a4a'}}>Servicekosten</p>
								<p style={{fontSize: 13, color: '#4a4a4a'}}>Wanneer je kiest voor 'Servicekosten doorbelasten' betaald de bezoeker de servicekosten. Dit wordt duidelijk gecommuniceerd naar de bezoeker.</p>								
								<div style={{width: '100%', backgroundColor: 'rgba(0,0,0,0.1)', height: 1, marginTop: 30, marginBottom: 30}}></div>
								<div style={{display: 'flex', marginTop: 20}}>
									<div style={{width: 200}}><p style={{fontSize: 13, color: '#4a4a4a'}}>Vast Tarief:</p></div>
									<div style={{width: 200}}><p style={{fontSize: 13, color: 'rgb(74, 74, 74,0.8)'}}>€ {this.props.observableStore.currEvent.pay.serviceFee.start.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})} (incl. BTW)</p></div>
								</div>
								<div style={{display: 'flex'}}>
									<div style={{width: 200}}><p style={{fontSize: 13, color: '#4a4a4a'}}>Percentage:</p></div>
									<div style={{width: 200}}><p style={{fontSize: 13, color: 'rgb(74, 74, 74,0.8)'}}>{this.props.observableStore.currEvent.pay.serviceFee.percentage.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}% (incl. BTW)</p></div>
								</div>
								<div style={{display: 'flex'}}>
									<div style={{width: 200}}><p style={{fontSize: 13, color: '#4a4a4a'}}>Tarief betaalmethode:</p></div>
									<div style={{width: 200}}><p style={{fontSize: 13, color: 'rgb(74, 74, 74,0.8)'}}>Check <a onClick={()=>this.setState({settingsCat: 'paymentmethods'})} style={{textDecoration: 'none', color: '#b32e5d', cursor: 'pointer'}}>betaalmethodes</a></p></div>
								</div>
								<p style={{fontSize: 11, color: '#4a4a4a', fontStyle: 'italic'}}>De berekening is vast tarief + percentage van het bestelbedrag + tarief betaalmethode.</p>
								<div style={{width: '100%', backgroundColor: 'rgba(0,0,0,0.1)', height: 1, marginTop: 30, marginBottom: 30}}></div>
								<div style={{display: 'flex'}}>
									<div style={{width: 200}}><p style={{fontSize: 13, color: '#4a4a4a'}}>Locker Toeslag:</p></div>
									<div style={{width: 200}}><p style={{fontSize: 13, color: 'rgb(74, 74, 74,0.8)'}}>€ {(this.props.observableStore.currEvent.pay.lockerFee ? this.props.observableStore.currEvent.pay.lockerFee : 0).toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})} (incl. BTW)</p></div>
								</div>
								<p style={{fontSize: 11, color: '#4a4a4a', fontStyle: 'italic'}}>Bij verkoop van een locker via Partypay geldt een lockertoeslag bovenop de servicekosten. Dit dekt extra handelingen en klantenservice bij lockers die niet werken of niet opengaan.</p>
								<div style={{width: '100%', backgroundColor: 'rgba(0,0,0,0.1)', height: 1, marginTop: 30}}></div>
							</Col>
						</Row>
					</Form>
					:null}

					{this.state.settingsCat === 'paymentmethods' ?
					<Form className="mt-1">
						<h2 className="font-light text-muted" style={{marginTop: 10, fontSize: 20, fontWeight: 900, color: '#212529',}}>Betaalmethodes</h2>
						<div className="addBtnLst addBtnLstSettings" style={{cursor: 'pointer', position: 'absolute', top: 20, right: 35, width: 150, background: '#d12a5e', color: '#fff', }} onClick={() => this.saveEventInfo('settings')}>Opslaan</div>
						<div style={{width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)', marginTop: 20, marginBottom: 30}}></div>
						<Row>
							<Col lg={5}>
								<SortableMethodList distance={1} helperClass='is-moving' onSortEnd={this.onMethodSortEnd} items={this.props.observableStore.currEvent.paymentMethods} togglePaymentMethod={this.togglePaymentMethod} toggleMethodInfoModal={this.toggleMethodInfoModal} />
							</Col>
							<Col lg={6} style={{paddingLeft: 50, paddingRight: 50}} className="explainCol">
								<label htmlFor="inputPassword" className="col-form-label">Accepteer een of meerdere betaalmethodes uit deze lijst om betalingen te ontvangen. Let op sommige betaalmethodes kunnen de servicekosten verhogen die aan de bezoeker wordt gerekend.</label>								
								<label style={{marginTop: 20}} htmlFor="inputPassword" className="col-form-label">Sleep een betaalmethode omhoog of omlaag om deze meer/minder onder de aandacht te brengen. De eerste betaalmethode wordt automatisch geselecteerd.</label>
							</Col>
						</Row>
						{this.props.observableStore.isAdmin ?
						<div className="v-middle" style={{marginBottom: 0, borderWidth: 0, maxWidth: 380}}>
							<div style={{width: '100%', display: 'flex', justifyContent: 'center'}}><div className="ticketshopAdd" onClick={() => this.toggleAddPaymentMethodModal()}>Voeg een betaalmethode toe</div></div>
						</div>
						:null}
					</Form>
					:null}
					
					{this.state.settingsCat === 'hardcups' ?
					<Form className="mt-1">
						<h2 className="font-light text-muted" style={{marginTop: 10, fontSize: 20, fontWeight: 900, color: '#212529',}}>Statiegeld Bekers</h2>
						<div className="addBtnLst addBtnLstSettings" style={{cursor: 'pointer', position: 'absolute', top: 20, right: 35, width: 150, background: '#d12a5e', color: '#fff', }} onClick={() => this.saveEventInfo('settings')}>Opslaan</div>
						<div style={{width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)', marginTop: 20, marginBottom: 30}}></div>
						<Row>
							<Col lg={5}>
								<div style={{maxWidth: 350}}>
									<div className="switchBtn">
										<label htmlFor="staticEmail" className="col-form-label" style={{marginTop: 2}}>Bekers aanslaan</label>
										<div style={{cursor: 'pointer', height: 29, width: 55, padding: 3, backgroundColor: this.props.observableStore.currEvent.hardcups.actief ? '#d12a5e' : '#ccc', borderRadius: 25}} onClick={() => {this.props.observableStore.currEvent.hardcups.actief = !this.props.observableStore.currEvent.hardcups.actief}}>
											<div style={{height: 23, width: 23, borderRadius: 12, backgroundColor: '#fff', float: this.props.observableStore.currEvent.hardcups.actief ? 'right' : 'left'}}></div>
										</div>
									</div>
									<div className="switchBtn">
										<label htmlFor="staticEmail" className="col-form-label" style={{marginTop: 2}}>Eerste beker gratis</label>
										<div style={{cursor: 'pointer', height: 29, width: 55, padding: 3, backgroundColor: this.props.observableStore.currEvent.hardcups.gratis ? '#d12a5e' : '#ccc', borderRadius: 25}} onClick={() => {this.props.observableStore.currEvent.hardcups.gratis = !this.props.observableStore.currEvent.hardcups.gratis}}>
											<div style={{height: 23, width: 23, borderRadius: 12, backgroundColor: '#fff', float: this.props.observableStore.currEvent.hardcups.gratis ? 'right' : 'left'}}></div>
										</div>
									</div>
									<div className="switchBtn">
										<label htmlFor="staticEmail" className="col-form-label" style={{marginTop: 2}}>Beker restitutie</label>
										<div style={{cursor: 'pointer', height: 29, width: 55, padding: 3, backgroundColor: this.props.observableStore.currEvent.hardcups.return ? '#d12a5e' : '#ccc', borderRadius: 25}} onClick={() => {this.props.observableStore.currEvent.hardcups.return = !this.props.observableStore.currEvent.hardcups.return}}>
											<div style={{height: 23, width: 23, borderRadius: 12, backgroundColor: '#fff', float: this.props.observableStore.currEvent.hardcups.return ? 'right' : 'left'}}></div>
										</div>
									</div>
									<FormGroup>
										<label htmlFor="staticEmail" className="col-form-label" style={{marginBottom: 2}}>Kosten statiegeld</label>
										<Input type="select" className='minimal' style={{width: '100%', height: 40, paddingBottom: 10, paddingTop: 10, paddingRight: 10, borderColor: 'rgb(217, 217, 217)', borderRadius: 12}} onChange={e => {this.props.observableStore.currEvent.hardcups.kosten = parseFloat(e.target.value)}}>
											<option value={0.5} selected={this.props.observableStore.currEvent.hardcups.kosten === 0.5}>0.5 muntje</option>
											<option value={1} selected={this.props.observableStore.currEvent.hardcups.kosten === 1}>1 muntje</option>
											<option value={1.5} selected={this.props.observableStore.currEvent.hardcups.kosten === 1.5}>1.5 muntje</option>
											<option value={2} selected={this.props.observableStore.currEvent.hardcups.kosten === 2}>2 muntjes</option>
											<option value={3} selected={this.props.observableStore.currEvent.hardcups.kosten === 3}>3 muntjes</option>
											<option value={4} selected={this.props.observableStore.currEvent.hardcups.kosten === 4}>4 muntjes</option>
										</Input>									
									</FormGroup>
									{this.props.observableStore.currEvent.hardcups.return ?
									<div>
										<h2 className="font-light text-muted" style={{marginTop: 40, fontSize: 18, color: '#212529',}}>Restitutie</h2>
										<div style={{width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)', marginTop: 15, marginBottom: 20}}></div>
										<FormGroup>
											<label htmlFor="staticEmail" className="col-form-label" style={{marginBottom: 2}}>Statiegeld terug</label>
											<Input type="select" className='minimal' style={{width: '100%', height: 40, paddingBottom: 10, paddingTop: 10, paddingRight: 10, borderColor: 'rgb(217, 217, 217)', borderRadius: 12}} onChange={e => {this.props.observableStore.currEvent.hardcups.kostenTerug = parseFloat(e.target.value)}}>
												<option value={null} selected={!this.props.observableStore.currEvent.hardcups.kostenTerug}>Hetzelfde als kosten statiegeld</option>
												<option value={0.5} selected={this.props.observableStore.currEvent.hardcups.kostenTerug === 0.5}>0.5 muntje</option>
												<option value={1} selected={this.props.observableStore.currEvent.hardcups.kostenTerug === 1}>1 muntje</option>
												<option value={1.5} selected={this.props.observableStore.currEvent.hardcups.kostenTerug === 1.5}>1.5 muntje</option>
												<option value={2} selected={this.props.observableStore.currEvent.hardcups.kostenTerug === 2}>2 muntjes</option>
												<option value={3} selected={this.props.observableStore.currEvent.hardcups.kostenTerug === 3}>3 muntjes</option>
												<option value={4} selected={this.props.observableStore.currEvent.hardcups.kostenTerug === 4}>4 muntjes</option>
											</Input>									
										</FormGroup>
										<FormGroup style={{marginTop: 20}}>
											<label htmlFor="staticEmail" className="col-form-label" style={{marginBottom: 2}}>Max inleveren per persoon</label>
											<InputGroup>
												<Input type="number" style={{height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10}} placeholder="bijvoorbeeld 2" value={this.props.observableStore.currEvent.hardcups.max ? this.props.observableStore.currEvent.hardcups.max : ''} onChange={e => this.props.observableStore.currEvent.hardcups.max = parseFloat(e.target.value)}/>
											</InputGroup>
										</FormGroup>
									</div>
									:null}
								</div>
							</Col>
							<Col lg={6} style={{paddingLeft: 50, paddingRight: 50}} className="explainCol">
								<label htmlFor="inputPassword" className="col-form-label">Wanneer "recyclable cups aanslaan" is geactiveerd krijgt de barmedewerker de mogelijkheid om niet ingeleverde cups aan te slaan op de scanterminal. De kosten worden verhaald op de gebruiker die de bestelling afscant.</label>
								<label style={{marginTop: 20}} htmlFor="inputPassword" className="col-form-label">Indien je kiest voor "eerste cup gratis" krijgt de barmedewerker bij de eerste bestelling te zien dat er geen cups hoeven te worden aangeslagen.</label>
								<label style={{marginTop: 20}} htmlFor="inputPassword" className="col-form-label">Indien je kiest voor "Beker restitutie" kan de bezoeker zijn beker(s) inleveren en een qrode scannen bij de bar terminals om zijn of haar munjes terug te krijgen.</label>
								<label style={{marginTop: 20}} htmlFor="inputPassword" className="col-form-label">Indien je kiest voor "Toevoegen aan bestelling" worden de bekers automatisch in rekening gebracht en moeten de barmedewerkers bekers aanslaan die terug komen.</label>
							</Col>
						</Row>
					</Form>
					:null}

					{this.state.settingsCat === 'koptelefoons' ?
					<Form className="mt-1">
						<h2 className="font-light text-muted" style={{marginTop: 10, fontSize: 20, fontWeight: 900, color: '#212529',}}>Koptelefoons</h2>
						<div className="addBtnLst addBtnLstSettings" style={{cursor: 'pointer', position: 'absolute', top: 20, right: 35, width: 150, background: '#d12a5e', color: '#fff', }} onClick={() => this.saveEventInfo('settings')}>Opslaan</div>
						<div style={{width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)', marginTop: 20, marginBottom: 30}}></div>
						<Row>
							<Col lg={5}>
								<div style={{maxWidth: 350}}>
									<div className="switchBtn">
										<label htmlFor="staticEmail" className="col-form-label" style={{marginTop: 2}}>Koptelefoon verhuur aan</label>
										<div style={{cursor: 'pointer', height: 29, width: 55, padding: 3, backgroundColor: this.props.observableStore.currEvent.headphones && this.props.observableStore.currEvent.headphones.actief ? '#d12a5e' : '#ccc', borderRadius: 25}} onClick={() => {if(!this.props.observableStore.currEvent.headphones){this.props.observableStore.currEvent.headphones = {}} this.props.observableStore.currEvent.headphones.actief = !this.props.observableStore.currEvent.headphones.actief}}>
											<div style={{height: 23, width: 23, borderRadius: 12, backgroundColor: '#fff', float:  this.props.observableStore.currEvent.headphones && this.props.observableStore.currEvent.headphones.actief ? 'right' : 'left'}}></div>
										</div>
									</div>
									{this.props.observableStore.currEvent.headphones && this.props.observableStore.currEvent.headphones.actief ?
									<div>
									<FormGroup>
										<label htmlFor="staticEmail" className="col-form-label" style={{marginBottom: 2}}>Huurprijs</label>
										<Input type="select" className='minimal' style={{width: '100%', height: 40, paddingBottom: 10, paddingTop: 10, paddingRight: 10, borderColor: 'rgb(217, 217, 217)', borderRadius: 12}} onChange={e => {this.props.observableStore.currEvent.headphones.kosten = parseFloat(e.target.value)}}>
											<option value={0} selected={this.props.observableStore.currEvent.headphones.kosten === 0}>0 muntjes</option>
											<option value={1} selected={this.props.observableStore.currEvent.headphones.kosten === 1}>1 muntje</option>
											<option value={2} selected={this.props.observableStore.currEvent.headphones.kosten === 2}>2 muntjes</option>
											<option value={3} selected={this.props.observableStore.currEvent.headphones.kosten === 3}>3 muntjes</option>
											<option value={4} selected={this.props.observableStore.currEvent.headphones.kosten === 4}>4 muntjes</option>
											<option value={5} selected={this.props.observableStore.currEvent.headphones.kosten === 5}>5 muntjes</option>
										</Input>						
									</FormGroup>
									<FormGroup>
										<label htmlFor="staticEmail" className="col-form-label" style={{marginBottom: 2}}>Borg</label>
										<Input type="select" className='minimal' style={{width: '100%', height: 40, paddingBottom: 10, paddingTop: 10, paddingRight: 10, borderColor: 'rgb(217, 217, 217)', borderRadius: 12}} onChange={e => {this.props.observableStore.currEvent.headphones.deposit = parseFloat(e.target.value)}}>
											<option value={0} selected={this.props.observableStore.currEvent.headphones.kosten === 0}>0 muntjes</option>
											<option value={1} selected={this.props.observableStore.currEvent.headphones.deposit === 1}>1 muntje</option>
											<option value={2} selected={this.props.observableStore.currEvent.headphones.deposit === 2}>2 muntjes</option>
											<option value={3} selected={this.props.observableStore.currEvent.headphones.deposit === 3}>3 muntjes</option>
											<option value={4} selected={this.props.observableStore.currEvent.headphones.deposit === 4}>4 muntjes</option>
											<option value={5} selected={this.props.observableStore.currEvent.headphones.deposit === 5}>5 muntjes</option>
											<option value={10} selected={this.props.observableStore.currEvent.headphones.deposit === 10}>10 muntjes</option>
											<option value={15} selected={this.props.observableStore.currEvent.headphones.deposit === 15}>15 muntjes</option>
										</Input>						
									</FormGroup>
									</div>
									:null}
								</div>
							</Col>
							<Col lg={6} style={{paddingLeft: 50, paddingRight: 50}} className="explainCol">
								<label htmlFor="inputPassword" className="col-form-label">Wanneer je koptelefoon verhuur aan zet komt er voor bezoekers een extra optie in de app waarbij ze een QR-Code kunnen laten scannen om huur en borg voor silent disco koptelefoons te betalen. Ook kunnen bezoekers op deze manier de borg weer terug krijgen.</label>
							</Col>
						</Row>
					</Form>
					:null}

					{this.state.settingsCat === 'betaalkaarten' ?
					<Form className="mt-1">
						<h2 className="font-light text-muted" style={{marginTop: 10, fontSize: 20, fontWeight: 900, color: '#212529',}}>Betaalkaarten & Bandjes</h2>
						<div className="addBtnLst addBtnLstSettings" style={{cursor: 'pointer', position: 'absolute', top: 20, right: 35, width: 150, background: '#d12a5e', color: '#fff', }} onClick={() => this.saveEventInfo('settings')}>Opslaan</div>
						<div style={{width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)', marginTop: 20, marginBottom: 30}}></div>
						<Row>
							<Col lg={5}>
								<div style={{maxWidth: 350}}>
									<div className="switchBtn">
										<label htmlFor="staticEmail" className="col-form-label" style={{marginTop: 2}}>Koppelen met de app</label>
										<div style={{cursor: 'pointer', height: 29, width: 55, padding: 3, backgroundColor: this.props.observableStore.currEvent.wristband ? '#d12a5e' : '#ccc', borderRadius: 25}} onClick={() => {this.props.observableStore.currEvent.wristband = !this.props.observableStore.currEvent.wristband}}>
											<div style={{height: 23, width: 23, borderRadius: 12, backgroundColor: '#fff', float: this.props.observableStore.currEvent.wristband ? 'right' : 'left'}}></div>
										</div>
									</div>
									<div className="switchBtn">
										<label htmlFor="staticEmail" className="col-form-label" style={{marginTop: 2}}>Landing Pagina Kleur</label>
										<div style={{display: 'flex', alignItems: 'center', marginTop: -2}}>
											<div onClick={()=> this.setState({displayColorPicker: !this.state.displayColorPicker})} style={{padding: '5px', background: '#fff', borderRadius: '5px', boxShadow: '0 0 0 1px rgba(0,0,0,.1)', display: 'inline-block', cursor: 'pointer'}}>
												<div style={{ width: '36px', height: '14px', borderRadius: '2px', background: this.props.observableStore.currEvent.webcolor ? this.props.observableStore.currEvent.webcolor : '#b32e5d'}}/>
											</div>
											{this.state.displayColorPicker ?
												<div style={{position: 'absolute', zIndex: 2 }}>
												<div onClick={()=> this.setState({displayColorPicker: !this.state.displayColorPicker})} style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, }}/>
												<SketchPicker color={this.props.observableStore.currEvent.webcolor ? this.props.observableStore.currEvent.webcolor : '#b32e5d'} onChange={(color)=> {this.props.observableStore.currEvent.webcolor = color.hex}} />
												</div>
											:null}
											<i class="fa-light fa-arrow-rotate-left" style={{marginLeft: 8, marginRight: -5, fontSize: 10}}  onClick={()=> {this.props.observableStore.currEvent.webcolor = '#b32e5d'}}></i>
										</div>
									</div>
									<h2 className="font-light text-muted" style={{marginTop: 35, fontSize: 18, color: '#212529',}}>Landing Pagina Buttons</h2>
									<div style={{width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)', marginTop: 15, marginBottom: 20}}></div>
									<SortableLinkList distance={1} helperClass='is-moving' onSortEnd={this.onLinkSortEnd} items={this.props.observableStore.currEvent.weblinks ? this.props.observableStore.currEvent.weblinks : []} editLink={this.toggleWeblinkModal} delLink={this.delWeblink} />
									<div style={{width: '100%', display: 'flex', justifyContent: 'center'}}><div className="ticketshopAdd" onClick={() => this.toggleWeblinkModal()}>Nieuwe button toevoegen</div></div>
								</div>
							</Col>
							<Col lg={6} style={{paddingLeft: 50, paddingRight: 50}} className="explainCol">
								<label style={{marginTop: 15}} htmlFor="inputPassword" className="col-form-label">Wanneer je ‘Koppelen met de app’ inschakelt, verschijnt in de Partypay-app de optie om betaalkaarten of pasjes toe te voegen. Dit is alleen mogelijk met kaarten of pasjes die nog niet eerder zijn gebruikt.</label>
								<label style={{marginTop: 20}} htmlFor="inputPassword" className="col-form-label">Op de webpagina die bezoekers zien nadat ze hun bandje of pasje hebben gescand, kun je de hoofdkleur van de knoppen, het saldovenster en de statusbalk aanpassen.</label>
								<label style={{marginTop: 20}} htmlFor="inputPassword" className="col-form-label">Je kan knoppen toevoegen aan de 'informatie' sectie op de webpagina waar bezoekers terecht komen na dat ze hun pasje of bandje scannen.</label>
							</Col>
						</Row>
					</Form>
					:null}

					{this.state.settingsCat === 'integraties' ?
					<Form className="mt-1">
						<h2 className="font-light text-muted" style={{marginTop: 10, fontSize: 20, fontWeight: 900, color: '#212529',}}>Integraties</h2>
						<div style={{width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)', marginTop: 20, marginBottom: 30}}></div>
						<Row>
							<Col lg={5}>
								<div style={{maxWidth: 350}}>
									{this.props.observableStore.currEvent.apiKeys ? this.props.observableStore.currEvent.apiKeys.map((key, index) => (
									<div className="apikeyContainer">
										<h2 className="font-light text-muted" style={{fontSize: 14, color: '#212529', fontWeight: 900}}>Notitie</h2>
										<h2 className="font-light text-muted" style={{fontSize: 13, color: 'rgba(0,0,0,0.5)'}}>{key.note}</h2>
										<h2 className="font-light text-muted" style={{fontSize: 14, marginTop: 10, color: '#212529', fontWeight: 900}}>API Sleutel</h2>
										<h2 className="font-light text-muted" style={{width: 200, wordBreak: 'break-all', fontSize: 12, color: 'rgba(0,0,0,0.4)'}}>{key.key}</h2>
										<div style={{width: '100%', display: 'flex', justifyContent: 'center'}}><div className="settingsListBtn" onClick={() => this.delApiKey(index)}>API-Sleutel intrekken</div></div>
									</div>
									)):null}
									<div style={{width: '100%', display: 'flex', justifyContent: 'center'}}><div className="ticketshopAdd" onClick={() => this.toggleApiModal()}>Genereer Nieuwe API-Sleutel</div></div>
								</div>
							</Col>
							<Col lg={6} style={{paddingLeft: 50, paddingRight: 50}} className="explainCol">
								<label style={{marginTop: 15}} htmlFor="inputPassword" className="col-form-label">Op deze pagina kun je eenvoudig API-sleutels beheren. Je hebt de mogelijkheid om een nieuwe API-sleutel te genereren en daarbij een optionele notitie toe te voegen om het gebruik te documenteren. Daarnaast kun je bestaande API-sleutels intrekken wanneer ze niet langer nodig zijn of wanneer de toegang moet worden beperkt.</label>
								<label style={{marginTop: 20}} htmlFor="inputPassword" className="col-form-label">Het is belangrijk op te merken dat elke API-sleutel gekoppeld is aan een specifiek evenement, wat betekent dat je volledige controle hebt over welke toegang wordt verleend per evenement. Deze functies maken het beheren van API-sleutels veilig en overzichtelijk.</label>
							</Col>
						</Row>
					</Form>
					:null}
					
					{this.state.settingsCat === 'organisatie' ?	
					<Form className="mt-1">
						<h2 className="font-light text-muted" style={{marginTop: 10, fontSize: 20, fontWeight: 900, color: '#212529',}}>Organisatie</h2>
						<div className="addBtnLst addBtnLstSettings" style={{cursor: 'pointer', position: 'absolute', top: 20, right: 35, width: 150, background: '#d12a5e', color: '#fff', }} onClick={() => this.saveOrganisatieInfo()}>Opslaan</div>
						<div style={{width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)', marginTop: 20, marginBottom: 30}}></div>
						<Row>
							<Col lg={6}>
								<div style={{maxWidth: 450}}>	
									<h2 className="font-light text-muted" style={{fontSize: 18, color: '#212529',}}>Bedrijfsgegevens</h2>
									<div style={{width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)', marginTop: 15, marginBottom: 20}}></div>
									<FormGroup style={{marginBottom: 20}}>
										<InputGroup>
											<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
												<InputGroupText style={{background: '#fff', width: 170, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>Naam onderneming</InputGroupText>
											</InputGroupAddon>
											<Input type="text" style={{height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10}} placeholder=""  value={this.props.observableStore.userInfo.bedrijfsnaam ? this.props.observableStore.userInfo.bedrijfsnaam : ''} onChange={e => this.props.observableStore.userInfo.bedrijfsnaam = e.target.value}/>
										</InputGroup>
									</FormGroup>
									<FormGroup style={{marginBottom: 20}}>
										<InputGroup>
											<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
												<InputGroupText style={{background: '#fff', width: 170, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>Type onderneming</InputGroupText>
											</InputGroupAddon>
											<Input type="text" style={{height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10}} placeholder=""  value={this.props.observableStore.userInfo.bedrijfstype ? this.props.observableStore.userInfo.bedrijfstype : ''} onChange={e => this.props.observableStore.userInfo.bedrijfstype = e.target.value}/>
										</InputGroup>
									</FormGroup>	
									<FormGroup style={{marginBottom: 20}}>
										<InputGroup>
											<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
												<InputGroupText style={{background: '#fff', width: 170, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>Website</InputGroupText>
											</InputGroupAddon>
											<Input type="text" style={{height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10}} placeholder=""  value={this.props.observableStore.userInfo.website ? this.props.observableStore.userInfo.website : ''} onChange={e => this.props.observableStore.userInfo.website = e.target.value}/>
										</InputGroup>
									</FormGroup>
									<FormGroup style={{marginBottom: 20}}>
										<InputGroup>
											<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
												<InputGroupText style={{background: '#fff', width: 170, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>Straat, nummer</InputGroupText>
											</InputGroupAddon>
											<Input type="text" style={{height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10}} placeholder=""  value={this.props.observableStore.userInfo.adres ? this.props.observableStore.userInfo.adres : ''} onChange={e => this.props.observableStore.userInfo.adres = e.target.value}/>
										</InputGroup>
									</FormGroup>
									<FormGroup style={{marginBottom: 20}}>
										<InputGroup>
											<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
												<InputGroupText style={{background: '#fff', width: 170, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>Postcode, plaats</InputGroupText>
											</InputGroupAddon>
											<Input type="text" style={{height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10}} placeholder=""  value={this.props.observableStore.userInfo.postcodeplaats ? this.props.observableStore.userInfo.postcodeplaats : ''} onChange={e => this.props.observableStore.userInfo.postcodeplaats = e.target.value}/>
										</InputGroup>
									</FormGroup>
									<h2 className="font-light text-muted" style={{marginTop: 35, fontSize: 18, color: '#212529',}}>Financieel</h2>
									<div style={{width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)', marginTop: 15, marginBottom: 20}}></div>
									<FormGroup style={{marginBottom: 20}}>
										<InputGroup>
											<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
												<InputGroupText style={{background: '#fff', width: 170, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>IBAN</InputGroupText>
											</InputGroupAddon>
											<Input type="text" style={{height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10}} placeholder=""  value={this.props.observableStore.userInfo.iban ? this.props.observableStore.userInfo.iban : ''} onChange={e => this.props.observableStore.userInfo.iban = e.target.value}/>
										</InputGroup>
									</FormGroup>
									<FormGroup style={{marginBottom: 20}}>
										<InputGroup>
											<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
												<InputGroupText style={{background: '#fff', width: 170, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>BIC</InputGroupText>
											</InputGroupAddon>
											<Input type="text" style={{height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10}} placeholder=""  value={this.props.observableStore.userInfo.bic ? this.props.observableStore.userInfo.bic : ''} onChange={e => this.props.observableStore.userInfo.bic = e.target.value}/>
										</InputGroup>
									</FormGroup>
									<FormGroup style={{marginBottom: 20}}>
										<InputGroup>
											<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
												<InputGroupText style={{background: '#fff', width: 170, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>BTW nummer</InputGroupText>
											</InputGroupAddon>
											<Input type="text" style={{height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10}} placeholder=""  value={this.props.observableStore.userInfo.btw ? this.props.observableStore.userInfo.btw : ''} onChange={e => this.props.observableStore.userInfo.btw = e.target.value}/>
										</InputGroup>
									</FormGroup>
									<h2 className="font-light text-muted" style={{marginTop: 35, fontSize: 18, color: '#212529',}}>Contactpersoon</h2>
									<div style={{width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)', marginTop: 15, marginBottom: 20}}></div>
									<FormGroup style={{marginBottom: 20}}>
										<InputGroup>
											<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
												<InputGroupText style={{background: '#fff', width: 170, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>Naam</InputGroupText>
											</InputGroupAddon>
											<Input type="text" style={{height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10}} placeholder=""  value={this.props.observableStore.userInfo.contactpersoon ? this.props.observableStore.userInfo.contactpersoon : ''} onChange={e => this.props.observableStore.userInfo.contactpersoon = e.target.value}/>
										</InputGroup>
									</FormGroup>
									<FormGroup style={{marginBottom: 20}}>
										<InputGroup>
											<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
												<InputGroupText style={{background: '#fff', width: 170, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>Telefoonnummer</InputGroupText>
											</InputGroupAddon>
											<Input type="text" style={{height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10}} placeholder="" value={this.props.observableStore.userInfo.contactnum ? this.props.observableStore.userInfo.contactnum : ''} onChange={e => this.props.observableStore.userInfo.contactnum = e.target.value}/>
										</InputGroup>
									</FormGroup>
								</div>
							</Col>
						</Row>
					</Form>
					:null}
					
					{this.state.settingsCat === 'account' ?
					<Form className="mt-1">
						<h2 className="font-light text-muted" style={{marginTop: 10, fontSize: 20, fontWeight: 900, color: '#212529',}}>Account info Wijzigen</h2>
						<div style={{width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)', marginTop: 20, marginBottom: 30}}></div>
						<Row>
							<Col lg={6}>
								<div style={{maxWidth: 450}}>
									<h2 className="font-light text-muted" style={{fontSize: 18, color: '#212529',}}>Wijzig e-mailadres</h2>
									<div style={{width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)', marginTop: 15, marginBottom: 20}}></div>
									<FormGroup>
										<InputGroup>
											<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
												<InputGroupText style={{background: '#fff', width: 125, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>E-mailadres</InputGroupText>
											</InputGroupAddon>
											<Input type="text" style={{height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10}} placeholder="" value={this.state.mail} onChange={e => this.setState({mail: e.target.value})}/>
										</InputGroup>
										<div style={{marginTop: 25, width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
											<div className="addBtnLst addBtnLstSettings" style={{marginBottom: 15, cursor: 'pointer', width: 150, background: '#d12a5e', color: '#fff'}} onClick={() => this.checkMail()}>Opslaan</div>
										</div>
									</FormGroup>
									<h2 className="font-light text-muted" style={{fontSize: 18, color: '#212529',}}>Wijzig wachtwoord</h2>
									<div style={{width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)', marginTop: 15, marginBottom: 20}}></div>
									<FormGroup style={{marginBottom: 20}}>
										<InputGroup>
											<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
												<InputGroupText style={{background: '#fff', width: 170, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>Nieuw wachtwoord</InputGroupText>
											</InputGroupAddon>
											<Input type="password" style={{height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10, marginBottom: 15}} placeholder="********" value={this.state.password} onChange={e => this.setState({password: e.target.value})}/>
										</InputGroup>
										<PasswordStrengthBar password={this.state.password} shortScoreWord="te kort" scoreWords={['zwak', 'zwak', 'gemiddeld', 'goed', 'sterk']} barColors={['#ddd', '#ef4836', '#ef4836', '#25c281', '#25c281']} onChangeScore={(score, feedback) => this.setState({passwordSecure: score})}/>
									</FormGroup>
									<FormGroup>
										<InputGroup>
											<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
												<InputGroupText style={{background: '#fff', width: 170, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>Herhaal wachtwoord</InputGroupText>
											</InputGroupAddon>
											<Input type="password" style={{height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10, marginBottom: 15}} placeholder="********" value={this.state.passwordRepeat} onChange={e => this.setState({passwordRepeat: e.target.value})}/>
										</InputGroup>
										<PasswordStrengthBar password={this.state.passwordRepeat} shortScoreWord="te kort" scoreWords={['zwak', 'zwak', 'gemiddeld', 'goed', 'sterk']} barColors={['#ddd', '#ef4836', '#ef4836', '#25c281', '#25c281']}/>
										<div style={{marginTop: 25, width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
											<div className="addBtnLst addBtnLstSettings" style={{marginBottom: 0, cursor: 'pointer', width: 150, background: '#d12a5e', color: '#fff'}} onClick={() => this.checkPass()}>Opslaan</div>
										</div>
									</FormGroup>
								</div>
							</Col>
							<Col lg={6} style={{paddingLeft: 50, paddingRight: 50, display: 'flex'}}>
								<label htmlFor="inputPassword" className="col-form-label">Voer a.u.b. een wachtwoord van minimaal 7 karakters in. Wij adviseren om zowel cijfers, capitalen als speciale tekens te gebruiken.</label>
							</Col>
						</Row>
					</Form>		
					:null}

				</Col>
			</Row>
			<Modal isOpen={this.state.saveModal} toggle={this.toggleSaveModal} className={this.props.className} centered >
				<ModalHeader toggle={this.toggle}>Wijzigingen opslaan</ModalHeader>
				<ModalBody>
					<p style={{marginTop: 10}}>
						Wijzigingen zijn opgeslagen.
					</p>
				</ModalBody>
				<ModalFooter>
					<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={this.toggleSaveModal}>
					  Sluit venster
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={this.state.accModal} toggle={this.toggleAccModal} className={this.props.className} centered >
				<ModalHeader toggle={this.toggleAccModal}>Wijzigingen opslaan</ModalHeader>
				<ModalBody>
					<p style={{marginTop: 10}}>
					 Wijzigingen zijn opgeslagen.
					</p>
				</ModalBody>
				<ModalFooter>
					<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={this.toggleAccModal}>
					  Sluit venster
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={this.state.infoModal} toggle={() => this.toggleInfoModal('')} className={this.props.className} centered >
				<ModalHeader toggle={this.toggleInfoModal}>Foutmelding</ModalHeader>
				<ModalBody>
					<p style={{marginTop: 10}}>{this.state.infoModalTxt}</p>
				</ModalBody>
				<ModalFooter>
					<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={() => this.toggleInfoModal('')}>
					  Sluit venster
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={this.state.currPassModal} toggle={this.toggleCurrPassModal} className={this.props.className} centered >
				<ModalHeader toggle={this.toggleCurrPassModal}>Authentificeren</ModalHeader>
				<ModalBody>
				<FormGroup>
					<label htmlFor="staticEmail" className="col-form-label">Vul je Huidige wachtwoord in</label>
					<InputGroup>
						<Input type="password" placeholder="********" value={this.state.oldpassword} onChange={e => this.setState({oldpassword: e.target.value})}/>
					</InputGroup>
				</FormGroup>				
				</ModalBody>
				<ModalFooter>
					<Button style={{backgroundColor: '#d12a5e', border: 0, marginLeft: 10}} onClick={() => this.updateAcc()}>
						Account info wijzigen
					</Button>
					<Button className="cancel_btn" style={{marginLeft: 10}} onClick={this.toggleCurrPassModal}>
					  Sluit venster
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={this.state.ticketShopModal} toggle={this.toggleTicketShopModal} className={this.props.className} centered style={{maxWidth: 430}}>
				<ModalHeader toggle={this.toggleTicketShopModal}>Ticketshop toevoegen</ModalHeader>
				<ModalBody>
					{this.state.ticketShopState === 1 ?
					<div>
						<FormGroup>
							<label htmlFor="staticEmail" className="col-form-label">Naam ticketshop</label>
							<InputGroup>
								<Input type="text" value={this.state.newTicketShopName} onChange={e => this.setState({newTicketShopName: e.target.value})}/>
							</InputGroup>
						</FormGroup>
						<FormGroup>
							<label htmlFor="staticEmail" className="col-form-label">Ticket provider</label>
							<Input type="select" style={{width: '100%', borderRadius: 10}} className='minimal' onChange={e => this.setState({newTicketShopProvider: e.target.value})}>
								<option value="" selected={this.state.newTicketShopProvider === ''}>Selecteer een ticketprovider</option>
								<option value="weeztix" selected={this.state.newTicketShopProvider === 'weeztix'}>Weeztix</option>
								<option value="your ticket provider" selected={this.state.newTicketShopProvider === 'your ticket provider'}>Your Ticket Provider</option>
								<option value="paylogic" selected={this.state.newTicketShopProvider === 'paylogic'}>Paylogic</option>
								<option value="gotickets" selected={this.state.newTicketShopProvider === 'gotickets'}>Go Tickets</option>
								<option value="four" selected={this.state.newTicketShopProvider === 'four'}>Four Venues</option>
								<option value="other" selected={this.state.newTicketShopProvider === 'other'}>Overig</option>
							</Input>
						</FormGroup>
						{this.state.newTicketShopProvider === 'your ticket provider' || this.state.newTicketShopProvider === 'gotickets' || this.state.newTicketShopProvider === 'four' ?
						<FormGroup>
							<label htmlFor="staticEmail" className="col-form-label">Event ID</label>
							<InputGroup>
								<Input type="text" value={this.state.newTicketShopEid} onChange={e => this.setState({newTicketShopEid: e.target.value})}/>
							</InputGroup>
						</FormGroup>	
						:null}
						<FormGroup>
							<label htmlFor="staticEmail" className="col-form-label">URL ticketshop</label>
							<InputGroup>
								<Input type="text" value={this.state.newTicketShopURL} onChange={e => this.setState({newTicketShopURL: e.target.value})}/>
							</InputGroup>
						</FormGroup>	
					</div>
					:null}
					{this.state.ticketShopState === 2 ?
						<div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 40, paddingBottom: 40}}>
							<div class="loading-spinner" style={{width: 35, height: 35, borderWidth: 4}}></div>
							<p style={{marginTop: 20}}>Ticketshop valideren</p>
						</div>
					:null}
					{this.state.ticketShopState === 3 ?
						<p style={{marginTop: 10}}>Er is iets mis gegaan, klopt de URL?</p>
					:null}	
				</ModalBody>
				<ModalFooter>
					<Button style={{backgroundColor: '#d12a5e', border: 0, marginLeft: 10}} onClick={() => this.addTicketShop()}>
						Toevoegen
					</Button>
					<Button className="cancel_btn" style={{marginLeft: 10}} onClick={this.toggleTicketShopModal}>
					  Sluit venster
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={this.state.timetableModal} toggle={this.toggleTimetableModal} className={this.props.className} centered >
				<ModalHeader toggle={this.toggleTimetableModal}>Timetable toevoegen</ModalHeader>
				<ModalBody>
					{this.state.uploadState ?
					<div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 40, paddingBottom: 40}}>
						<div class="loading-spinner" style={{width: 35, height: 35, borderWidth: 4}}></div>
					</div>
					:
					<div>
						<FormGroup>
							<Label htmlFor="example-email" style={{marginBottom: 10}}>Selecteer een dag</Label>
							<div className="lockerDate">
								<i class="fa fa-calendar-day" style={{position: 'absolute', right: 15, top: 13, color: '#525f7f'}}></i>
								<Datetime timeFormat={false} value={this.state.newTimetableDate} onChange={e => this.setState({newTimetableDate: e})} closeOnSelect={true} dateFormat="dddd D MMMM YYYY" locale="nl"/>
							</div>
						</FormGroup>	
						{this.state.newTimetableImg === '' ?
						<FormGroup className="mb-1">
							<Label htmlFor="example-email" style={{marginBottom: 10}}>Upload een timetable</Label>		
							<div className="custom-file">
								<Input innerRef={input => this.imgUpload3 = input} type="file" className="form-control" id="customFile1" onChange={e => this.loadTimetable(e)} />
								<label className="custom-file-label" htmlFor="customFile1">Kies een bestand</label>
							</div>
						</FormGroup>
						:
						<div style={{display: 'flex'}}>
							<i class="fa fa-check" style={{marginTop: 10}}></i>
							<label style={{marginLeft: 15}} htmlFor="inputPassword" className="col-form-label">Bestand is geupload</label>
						</div>
						}
					</div>
					}
				</ModalBody>
				<ModalFooter>
					<Button style={{backgroundColor: '#d12a5e', border: 0, marginLeft: 10}} onClick={() => this.addTimetable()}>
						Toevoegen
					</Button>
					<Button className="cancel_btn" style={{marginLeft: 10}} onClick={this.toggleTimetableModal}>
					  Sluit venster
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={this.state.fixedAmountModal} toggle={this.toggleFixedAmountModal} className={this.props.className} centered >
				<ModalHeader toggle={this.toggleFixedAmountModal}>Munt verkoop aantallen</ModalHeader>
				<ModalBody>
					<p style={{fontSize: 13, marginTop: 2, color: '#4a4a4a'}}>Zodra er vaste verkoop aantallen zijn ingesteld kunnen bezoekers alleen nog de aangegeven aantallen selecteren en niet zelf een gewenst aantal invoeren.</p>
					<SortableAmountList helperClass='is-moving' items={this.state.fixedAmountArray} onSortEnd={this.onAantalSortEnd} editAantal={this.editAantal} delAantal={this.delAantal} useDragHandle={true} />
					<div onClick={() => this.newAantal()} style={{display: 'flex', justifyContent: 'center'}}><div className='fixedAmountSetup' style={{marginTop: 25, marginBottom: 30, fontSize: 13, width: 160}}>Aantal toevoegen</div></div>
					<Modal isOpen={this.state.fixedAmountAddModal} toggle={() => this.fixedAmountAddModal} className={this.props.className} centered style={{maxWidth: 400}} >
						<ModalHeader toggle={this.fixedAmountAddModal}>Aantal {this.state.editAantalIndex === 'new' ? 'Toevoegen' : 'Aanpassen'}</ModalHeader>
						<ModalBody>
							<FormGroup>
								<Label htmlFor="example-email">Aantal</Label>
								<Input type="number" id="example-email" name="name" placeholder="Bijv: 4" value={this.state.newFixedAmount} onChange={e => this.setState({newFixedAmount: parseFloat(e.target.value).toString()})}/>
							</FormGroup>
						</ModalBody>
						<ModalFooter>
							<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={() => this.saveAantal()}>
								{this.state.editAantalIndex === 'new' ? 'Toevoegen' : 'Aanpassen'}
							</Button>
							<Button className="cancel_btn" style={{marginLeft: 10}} onClick={this.toggleFixedAmountAddModal}>
								Annuleren
							</Button>
						</ModalFooter>
					</Modal>
				</ModalBody>
				<ModalFooter>								
					<Button style={{backgroundColor: '#d12a5e', border: 0, marginLeft: 10}} onClick={() => this.saveAantallenArray()}>
						Opslaan
					</Button>
					<Button className="cancel_btn" style={{marginLeft: 10}} onClick={this.toggleFixedAmountModal}>
					  	Sluit venster
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={this.state.addPaymentMethodModal} toggle={this.toggleAddPaymentMethodModal} className={this.props.className} centered >
				<ModalHeader toggle={this.toggleAddPaymentMethodModal}>Betaalmethode toevoegen</ModalHeader>
				<ModalBody>
					<p style={{fontSize: 13, marginTop: 2, color: '#4a4a4a'}}>Voeg hieronder een nieuwe pay.nl betaalmethode toe in json formaat.</p>
					<p>Voorbeeld:</p>
					<p>{'{"id": "694", "name": "Giropay", "status": false, "base": 0.05, "per": 1.25, "retour": true, "retourfee": 0.25, "allowCollect": true, "collectMethod": "ppcollect1", "img": "https://firebasestorage.googleapis.com/v0/b/partypay-a3d12.appspot.com/o/paymentIcons%2Fgiropay3.svg?alt=media&token=c22edf87-d0a3-4773-9477-5044f3ac2362"}'}</p>
					<FormGroup>
						<Label htmlFor="example-email">Nieuwe methode</Label>
						<Input type="textarea" id="example-email" name="name" value={this.state.newPaymentMethod} onChange={e => this.setState({newPaymentMethod: e.target.value})}/>
					</FormGroup>
				</ModalBody>
				<ModalFooter>								
					<Button style={{backgroundColor: '#d12a5e', border: 0, marginLeft: 10}} onClick={() => this.addPaymentMethod()}>
						Toevoegen
					</Button>
					<Button className="cancel_btn" style={{marginLeft: 10}} onClick={this.toggleAddPaymentMethodModal}>
					  	Sluit venster
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={this.state.refundAllModal} toggle={this.toggleRefundAllModal} className={this.props.className} centered >
				<ModalHeader toggle={this.toggleCurrPassModal}>Alle muntjes retourneren</ModalHeader>
				<ModalBody>
					<p style={{marginTop: 10}}>Weet je zeker dat je alle overgebleven muntjes wilt retourneren?</p>			
				</ModalBody>
				<ModalFooter>
					<Button style={{backgroundColor: '#d12a5e', border: 0, marginLeft: 10}} onClick={() => this.refundAll()}>
						Alle muntjes retourneren
					</Button>
					<Button className="cancel_btn" style={{marginLeft: 10}} onClick={this.toggleRefundAllModal}>
					  Sluit venster
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={this.state.apiModal} toggle={this.toggleApiModal} className={this.props.className} centered >
				<ModalHeader toggle={this.toggleApiModal}>API Sleutel toevoegen</ModalHeader>
				<ModalBody>
					<p style={{fontSize: 13, marginTop: 2, color: '#4a4a4a'}}>Bij het aanmaken van een API-sleutel is het handig om gebruikersinformatie (naam, e-mail) en een korte beschrijving van het gebruik toe te voegen.</p>
					<FormGroup>
						<Label htmlFor="example-email">Notitie</Label>
						<Input type="textarea" id="example-email" name="name" value={this.state.newApiNote} onChange={e => this.setState({newApiNote: e.target.value})}/>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button style={{backgroundColor: '#d12a5e', border: 0, marginLeft: 10}} onClick={() => this.addApiKey()}>
						Toevoegen
					</Button>
					<Button className="cancel_btn" style={{marginLeft: 10}} onClick={this.toggleApiModal}>
					  Sluit venster
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={this.state.weblinkModal} toggle={this.toggleWeblinkModal} className={this.props.className} centered >
				<ModalHeader toggle={this.toggleWeblinkModal}>Weblink toevoegen</ModalHeader>
				<ModalBody>
					<p style={{fontSize: 13, marginTop: 2, color: '#4a4a4a'}}>Voeg hieronder een link toe aan de 'informatie' sectie op de webpagina waar bezoekers terecht komen na dat ze hun pasje of bandje scannen. Je kunt ook interne secties toevoegen. Bijvoorbeeld door #lockers of #klantenservice op te geven bij het URL veld.</p>
					<FormGroup>
						<Label htmlFor="example-email">Knop tekst</Label>
						<Input type="text" id="example-email" name="name" value={this.state.newWeblinkText} onChange={e => this.setState({newWeblinkText: e.target.value})}/>
					</FormGroup>
					<FormGroup>
						<Label htmlFor="example-email">URL</Label>
						<Input type="text" id="example-email" name="name" value={this.state.newWeblinkUrl} onChange={e => this.setState({newWeblinkUrl: e.target.value})}/>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button style={{backgroundColor: '#d12a5e', border: 0, marginLeft: 10}} onClick={() => this.addWeblink()}>
						Toevoegen
					</Button>
					<Button className="cancel_btn" style={{marginLeft: 10}} onClick={this.toggleWeblinkModal}>
					  Sluit venster
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={this.state.cropModal} toggle={this.toggleCropModal} className={this.props.className} centered >
				<ModalHeader toggle={this.toggleCropModal}>Afbeelding bijsnijden</ModalHeader>
				<ModalBody>
					<div style={{width: '100%'}}>
						<div style={{position: 'relative', width: '100%', height: 254, width: 452, overflow: 'hidden'}}>
							<Cropper
								image={this.state.imageSrc}
								crop={this.state.crop}
								zoom={this.state.zoom}
								aspect={768 / 432}
								onCropChange={this.onCropChange}
								onCropComplete={(croppedArea, croppedAreaPixels) => {this.setState({pixelCrop: croppedAreaPixels})}}
								restrictPosition={false}
								showGrid={true}
							/>
						</div>
						<input type="range" min="0" max="3" step="0.1" value={this.state.zoom} onChange={e => this.setState({zoom: e.target.value})} style={{marginTop: 20, width: '100%', appearance: 'none', height: '8px', background: '#ddd', borderRadius: '5px', outline: 'none' }} />
					</div>
				</ModalBody>
				<ModalFooter>
					<Button style={{backgroundColor: '#d12a5e', border: 0, marginLeft: 10}} onClick={() => this.saveImage()}>
						Uploaden
					</Button>
					<Button className="cancel_btn" style={{marginLeft: 10}} onClick={() => this.saveImageNoCrop()}>
					  	Uploaden zonder bijsnijden
					</Button>
					<Button className="cancel_btn" style={{marginLeft: 10}} onClick={() => this.toggleCropModal()}>
					  	Sluit venster
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={this.state.methodInfoModal} toggle={this.toggleMethodInfoModal} className={this.props.className} centered >
				<ModalHeader toggle={this.toggleMethodInfoModal}>{this.props.observableStore.currEvent.paymentMethods[this.state.methodInfoId].name}</ModalHeader>
				<ModalBody>
					<p style={{fontSize: 13, marginTop: 10, color: '#4a4a4a'}}>Hieronder vind je een uitgebreid overzicht van de mogelijkheden en het kostenplaatje van de betaalmethode {this.props.observableStore.currEvent.paymentMethods[this.state.methodInfoId].name}. Deze kosten worden standaard doorbelast aan de bezoeker maar dit kun je zelf aanpassen bij {'instellingen > transacties'}</p>
					<div style={{width: '100%', backgroundColor: 'rgba(0,0,0,0.1)', height: 1, marginTop: 30, marginBottom: 30}}></div>
					<div style={{display: 'flex'}}>
						<div style={{width: 200}}><p style={{fontSize: 13, color: '#4a4a4a'}}>Betaalmethode:</p></div>
						<div style={{width: 200}}><p style={{fontSize: 13, color: 'rgb(74, 74, 74,0.8)'}}>{this.props.observableStore.currEvent.paymentMethods[this.state.methodInfoId].name}</p></div>
					</div>
					<div style={{display: 'flex'}}>
						<div style={{width: 200}}><p style={{fontSize: 13, color: '#4a4a4a'}}>Status:</p></div>
						<div style={{width: 200}}><p style={{fontSize: 13, color: 'rgb(74, 74, 74,0.8)'}}>{this.props.observableStore.currEvent.paymentMethods[this.state.methodInfoId].status ? 'Ingeschakeld' : 'Uitgeschakeld'}</p></div>
					</div>
					<div style={{display: 'flex'}}>
						<div style={{width: 200}}><p style={{fontSize: 13, color: '#4a4a4a'}}>Op Krediet Bestellen:</p></div>
						<div style={{width: 200}}><p style={{fontSize: 13, color: 'rgb(74, 74, 74,0.8)'}}>{this.props.observableStore.currEvent.paymentMethods[this.state.methodInfoId].allowCollect ? 'Ja' : 'Nee'}</p></div>
					</div>
					<div style={{display: 'flex'}}>
						<div style={{width: 200}}><p style={{fontSize: 13, color: '#4a4a4a'}}>Retourneren:</p></div>
						<div style={{width: 200}}><p style={{fontSize: 13, color: 'rgb(74, 74, 74,0.8)'}}>{this.props.observableStore.currEvent.paymentMethods[this.state.methodInfoId].retour ? 'Ja' : 'Nee'}</p></div>
					</div>
					<div style={{width: '100%', backgroundColor: 'rgba(0,0,0,0.1)', height: 1, marginTop: 20, marginBottom: 30}}></div>
					<p style={{fontSize: 17, color: '#4a4a4a'}}>Kosten</p>
					<div style={{display: 'flex', marginTop: 20}}>
						<div style={{width: 200}}><p style={{fontSize: 13, color: '#4a4a4a'}}>Vast Tarief:</p></div>
						<div style={{width: 200}}><p style={{fontSize: 13, color: 'rgb(74, 74, 74,0.8)'}}>€ {this.props.observableStore.currEvent.paymentMethods[this.state.methodInfoId].base.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})} (incl. BTW)</p></div>
					</div>
					<div style={{display: 'flex'}}>
						<div style={{width: 200}}><p style={{fontSize: 13, color: '#4a4a4a'}}>Percentage:</p></div>
						<div style={{width: 200}}><p style={{fontSize: 13, color: 'rgb(74, 74, 74,0.8)'}}>{this.props.observableStore.currEvent.paymentMethods[this.state.methodInfoId].per.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}% (incl. BTW)</p></div>
					</div>
					<div style={{display: 'flex'}}>
						<div style={{width: 200}}><p style={{fontSize: 13, color: '#4a4a4a'}}>Retour Tarief:</p></div>
						<div style={{width: 200}}><p style={{fontSize: 13, color: 'rgb(74, 74, 74,0.8)'}}>€ {this.props.observableStore.currEvent.paymentMethods[this.state.methodInfoId].retourfee.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})} (incl. BTW)</p></div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button className="cancel_btn" style={{marginLeft: 10}} onClick={this.toggleMethodInfoModal}>
					  Sluit venster
					</Button>
				</ModalFooter>
			</Modal>

			{this.props.observableStore.isAdmin ? <p style={{marginTop: 30, fontSize: 10, color: 'rgba(0,0,0,0.4)', marginBottom: 0}}>OrganiserID: {this.props.observableStore.uid} - EventID: {this.props.observableStore.currEvent.ID} - <a href={'https://console.firebase.google.com/project/partypay-a3d12/storage/partypay-a3d12.appspot.com/files/~2Fadmins~2F' + this.props.observableStore.uid + '~2Fevents~2F' + this.props.observableStore.currEvent.ID}>Bucket</a></p>:null}
		</div>
	)
	}

	state = {
		settingsCat: 'info',
		uploadState: false,
		uploadMapState: false,
		saveModal: false,
		modal: false,
		saveModal: false,
		accModal: false,
		infoModal: false,
		infoModalTxt: '',
		currPassModal: false,
		oldpassword: '',
		password: '',
		passwordRepeat: '',
		passwordSecure: 0,
		mail: this.props.observableStore.mail,
		changeMode: 'mail',
		
		ticketShopModal: false,
		ticketShopState: 1,
		newTicketShopURL: '',
		newTicketShopName: '',
		newTicketShopProvider: '',
		newTicketShopEid: '',

		timetableModal: false,
		newTimetableDate: new Date(),
		newTimetableImg: '',
		
		imageSrc: '',
		crop: { x: 0, y: 0 },
		zoom: 1,

		mapSrc: '',

		fixedAmountModal: false,
		fixedAmountAddModal: false,
		fixedAmountArray: [],
		newFixedAmount: '',
		editAantalIndex: '',

		addPaymentMethodModal: false,
		newPaymentMethod: '',

		refundAllModal: false,

		displayColorPicker: false,
		editWeblinkIndex: -1,
		newWeblinkText: '', 
		newWeblinkUrl: '',

		methodInfoModal: false,
		methodInfoId: 0

	}
	
	toggleActief = () => {
		this.props.observableStore.currEvent.actief = !this.props.observableStore.currEvent.actief	
	}
	
	toggletokenretour = (fee) => {
		var tokenretour = this.props.observableStore.currEvent.muntretour;		
		if(fee){tokenretour.transfee = fee}
		else{tokenretour.allowed = !this.props.observableStore.currEvent.muntretour.allowed}
		this.props.observableStore.currEvent.muntretour = tokenretour;
	}
	
	toggleSaveModal = () => this.setState({'saveModal': !this.state.saveModal});
	toggleAccModal = () => this.setState({'accModal': !this.state.accModal});
	toggleInfoModal = (error) => this.setState({'infoModal': !this.state.infoModal, 'infoModalTxt': error});
	toggleCurrPassModal = (changeMode) => this.setState({'currPassModal': !this.state.currPassModal, 'changeMode': changeMode});
	toggleTicketShopModal = () => this.setState({'ticketShopModal': !this.state.ticketShopModal, newTicketShopName: '', newTicketShopProvider: '', newTicketShopURL: '', newTicketShopEid: '', ticketShopState: 1});
	toggleTimetableModal = () => this.setState({'timetableModal': !this.state.timetableModal, newTimetableDate: new Date(), newTimetableImg: '', uploadState: false});
	toggleFixedAmountModal = () => this.setState({'fixedAmountModal': !this.state.fixedAmountModal, fixedAmountArray: this.props.observableStore.currEvent.muntaantal ? this.props.observableStore.currEvent.muntaantal : []});
	toggleFixedAmountAddModal = () => this.setState({'fixedAmountAddModal': !this.state.fixedAmountAddModal});
	toggleAddPaymentMethodModal = () => this.setState({'addPaymentMethodModal': !this.state.addPaymentMethodModal, newPaymentMethod: ''});
	toggleRefundAllModal = () => this.setState({'refundAllModal': !this.state.refundAllModal});
	toggleApiModal = () => this.setState({'apiModal': !this.state.apiModal, newApiNote: ''});
	toggleWeblinkModal = (ind) => this.setState({'weblinkModal': !this.state.weblinkModal, editWeblinkIndex: Number.isInteger(ind) ? ind : -1, newWeblinkText: Number.isInteger(ind) ? this.props.observableStore.currEvent.weblinks[ind].text : '', newWeblinkUrl: Number.isInteger(ind) ? this.props.observableStore.currEvent.weblinks[ind].url : ''});
	toggleCropModal = () => this.setState({'cropModal': !this.state.cropModal});
	toggleMethodInfoModal = (ind) => {this.setState({methodInfoModal: !this.state.methodInfoModal, methodInfoId: Number.isInteger(ind) ? ind : 0})}

	loadImg = (event) => {
		var file = event.target.files[0];	
		if(file !== undefined){
			if(file.type === 'image/png' || file.type === 'image/jpeg' ){
				var reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onloadend = () => {
					this.file = file;
					this.setState({imageSrc: reader.result, cropModal: true});
				}
			}
			else{alert('Bestand moet een jpeg of png afbeelding zijn.')}	  
		}
	}	

	loadTimetable = (event) => {
		var file = event.target.files[0];	
		if(file !== undefined){
			if(file.type === 'image/png' || file.type === 'image/jpeg' ){this.setState({newTimetableImg: file})}
			else{alert('Bestand moet een jpeg of png afbeelding zijn.')}	    
		}
	}	
	
	uploadImage = '';
	
	onCropChange = (crop) => {
		this.setState({ crop });
	}

	onZoomChange = (zoom) => {
		this.setState({ zoom });
	}
	
	formatDay(dag, dayonly){
		var weekdays = ["Zondag","Maandag","Dinsdag","Woensdag","Donderdag","Vrijdag","Zaterdag"];
		var date = new Date(0);
		date.setUTCSeconds(dag);
		if(dayonly){return weekdays[date.getDay()]}
		return ("0" + date.getDate()).slice(-2) + '-' + ("0" + (date.getMonth() +1)).slice(-2)  + '-' + date.getFullYear();
	}

	saveImage(){	 
		const canvas = document.createElement('canvas');
		var pixelCrop = this.state.pixelCrop;
		canvas.width = 768;
		canvas.height = 432;
		const ctx = canvas.getContext('2d');
		ctx.fillStyle = "white";
		ctx.fillRect(0, 0, pixelCrop.width, pixelCrop.height);
		var img = new Image();
		img.src = this.state.imageSrc;		
		img.onload = () => {
			ctx.drawImage(img, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, 0, 0, 768, 432);			
			canvas.toBlob(blob => {
				const filename = new Array(15).join().replace(/(.|$)/g, function(){return ((Math.random()*36)|0).toString(36)[Math.random()<.5?"toString":"toUpperCase"]()}) + '.jpeg';
				const file = new File([blob], filename, {type: blob.type});				
				this.setState({uploadState: true});
				var ImageRef = storage.ref().child('admins/' + this.props.observableStore.uid + '/events/' + this.props.observableStore.currEvent.ID + '/' + file.name);
				ImageRef.getDownloadURL().then((url) => {this.props.observableStore.currEvent.cover = url; this.setState({uploadState: false, imageSrc: ''})}).catch(err => {							
					ImageRef.put(file).then(() =>{ImageRef.getDownloadURL().then((url) => {this.props.observableStore.currEvent.cover = url; this.setState({uploadState: false, imageSrc: ''})})});
				})		
			},'image/jpeg');			
		}	
		this.toggleCropModal();
	}

	saveImageNoCrop(){	
		if(this.file.size > 204800){alert('Bestand mag maximaal 200kb groot zijn.'); return null}
		let img = new Image()
		img.src = window.URL.createObjectURL(this.file)
		img.onload = () => {
			if(img.width !== 768 || img.height !== 432){alert('Orginele afbeelding moet de afmeting 768x432 zijn'); return null}
			this.setState({uploadState: true});		
			var ImageRef = storage.ref().child('admins/' + this.props.observableStore.uid + '/events/' + this.props.observableStore.currEvent.ID + '/' + this.file.name);
			ImageRef.put(this.file).then(() =>{
				ImageRef.getDownloadURL().then((url) => {
					this.props.observableStore.currEvent.cover = url; 
					this.setState({uploadState: false, imageSrc: '', cropModal: false});
				})
			});
		}
	}

	saveMap(event) {	
		var file = event.target.files[0];
		if(file.size > 921600){alert('Bestand mag maximaal 900kb groot zijn.'); return null}	
		if(file !== undefined){
			if(file.type === 'image/png' || file.type === 'image/jpeg' ){
				this.setState({uploadMapState: true});		
				var ImageRef = storage.ref().child('admins/' + this.props.observableStore.uid + '/events/' + this.props.observableStore.currEvent.ID + '/' + 'festivalmap');
				ImageRef.put(file).then(()=>{
					ImageRef.getDownloadURL().then((url) => {this.props.observableStore.currEvent.festivalmap = url; this.setState({uploadMapState: false, mapSrc: ''})}).catch(err => {							
						ImageRef.put(file).then(() =>{ImageRef.getDownloadURL().then((url) => {this.props.observableStore.currEvent.festivalmap = url; this.setState({uploadMapState: false, mapSrc: ''})})});
					})		
				});
			}
			else{alert('Bestand moet een jpeg of png afbeelding zijn.')}	
		}  
	}	

	saveEventInfo = () => {
		this.props.observableStore.saveEventInfo('settings');
		this.toggleSaveModal();
	}
	
	saveOrganisatieInfo = () => {
		this.props.observableStore.setOrganisatieInfo();
		this.toggleAccModal();
	}
	
	checkMail(){
		if(!this.ValidateEmail(this.state.mail)){this.toggleInfoModal('Voer a.u.b. een valide email address in')}
		else{this.toggleCurrPassModal('mail')}
	}
	
	checkPass(){
		if(this.state.passwordSecure < 3){this.toggleInfoModal('Voer a.u.b. een sterk wachtwoord in')}
		else if(this.state.password !== this.state.passwordRepeat){this.toggleInfoModal('De wachtwoorden komen niet overeen')}
		else{this.toggleCurrPassModal('pass')}
	}
		
	updateAcc(){
		this.props.observableStore.updateAcc(this.state.changeMode, this.state.oldpassword, this.state.password, this.state.mail);
		this.toggleCurrPassModal();
	}
	
	ValidateEmail = (mail) => {
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)){return true;}
		return false;
	}
	
	setEventType(type){
		var tijd;
		if(type === 'event'){tijd = Math.round(new Date().getTime() / 1000)}
		if(type === 'locatie'){tijd = 2524611661}
		this.props.observableStore.currEvent.type = type;
		this.props.observableStore.currEvent.tijd = tijd;
	}
	
	setTime(mode, date){
		var open = this.props.observableStore.currEvent.tijd.split(' - ')[0];
		var close = this.props.observableStore.currEvent.tijd.split(' - ')[1];		
		if(mode === 'open'){open = moment(date._d).format('HH:mm')}
		if(mode === 'close'){close = moment(date._d).format('HH:mm')}
		this.props.observableStore.currEvent.tijd = (open + ' - ' + close);
	}
	
	setDate(date){
		this.props.observableStore.currEvent.tijd = date._d.getTime() / 1000;
	}

	setRetourDate(date){
		if(date._d){
			this.props.observableStore.currEvent.muntretour.date = date._d.getTime() / 1000;
			this.getRetourDate();
		}
	}

	getTime(mode){
		if(mode === 'event'){
			var d = new Date(0);
			d.setUTCSeconds(this.props.observableStore.currEvent.tijd);
			if(isNaN(d) || this.props.observableStore.currEvent.tijd === ''){return new Date()}
			return d;
		}
		if(mode === 'openingstijd'){return this.props.observableStore.currEvent.tijd.split(' - ')[0]}
		if(mode === 'sluitingstijd'){return this.props.observableStore.currEvent.tijd.split(' - ')[1]}
	}

	getRetourDate(){
		var d = new Date(0);
		d.setUTCSeconds(this.props.observableStore.currEvent.muntretour.date);
		if(isNaN(d) || this.props.observableStore.currEvent.muntretour.date === 0){return new Date()}
		return d;
	}
		
	addTicketShop(){
		if(this.state.newTicketShopProvider === 'your ticket provider' && this.state.newTicketShopEid === ''){this.toggleInfoModal('Het event ID is verplicht'); return ''}
		if(this.state.newTicketShopProvider === 'gotickets' && this.state.newTicketShopEid === ''){this.toggleInfoModal('Het event ID is verplicht'); return ''}
		if(this.state.newTicketShopProvider === 'four' && this.state.newTicketShopEid === ''){this.toggleInfoModal('Het event ID is verplicht'); return ''}
		this.setState({ticketShopState: 2},()=> {
			var shopName = this.state.newTicketShopName;
			var shopProvider = this.state.newTicketShopProvider;
			var shopURL = this.state.newTicketShopURL;
			var shopEid = this.state.newTicketShopEid;
			if(!this.props.observableStore.currEvent.ticketshops){this.props.observableStore.currEvent.ticketshops = []}
			if(!this.props.observableStore.currEvent.ticketids){this.props.observableStore.currEvent.ticketids = []}
			if(this.state.newTicketShopProvider === 'weeztix'){
				if(shopURL.includes('shop.weeztix.io')){
					var shopID = shopURL.split('shop.weeztix.io/')[1].split('/')[0];
					if(shopID && shopID !== ''){
						this.props.observableStore.currEvent.ticketshops.unshift({id: shopID, url: shopURL, naam: shopName, provider: shopProvider, eid: shopEid});
						this.props.observableStore.currEvent.ticketids.unshift(shopID);
						this.toggleTicketShopModal();
					}
					else{
						this.setState({ticketShopState: 3});
					}	
				}
				else{
					this.setState({ticketShopState: 1},()=>this.toggleInfoModal('Vul een url in dat begint met shop.weeztix.io'));
				}	
			}
			else if(this.state.newTicketShopProvider === 'paylogic'){
				if(shopURL.includes('shop.paylogic.com')){
					var shopID = shopURL.split('shop.paylogic.com')[1].replace(/\//g,'');
					if(shopID && shopID !== ''){
						this.props.observableStore.currEvent.ticketshops.unshift({id: shopID, url: shopURL, naam: shopName, provider: shopProvider, eid: shopEid});
						this.props.observableStore.currEvent.ticketids.unshift(shopID);
						this.toggleTicketShopModal();
					}
					else{
						this.setState({ticketShopState: 3});
					}	
				}
				else{
					this.setState({ticketShopState: 1},()=>this.toggleInfoModal('Vul een url in dat begint met shop.paylogic.com'));
				}	
			}
			else{
				this.props.observableStore.currEvent.ticketshops.unshift({url: shopURL, naam: shopName, provider: shopProvider, eid: shopEid});
				if(shopEid && shopEid !== ''){this.props.observableStore.currEvent.ticketids.unshift(shopEid)}
				this.toggleTicketShopModal();
			}
		})
	}

	delTicketshop(ind){
		this.props.observableStore.currEvent.ticketshops.splice(ind, 1);
		if(this.props.observableStore.currEvent.ticketids){this.props.observableStore.currEvent.ticketids.splice(ind, 1)}
	}

	addTimetable(){
		var file = this.state.newTimetableImg;
		if(file.size > 921600){alert('Bestand mag maximaal 900kb groot zijn.'); return null}		
		var day = Math.round(new Date(this.state.newTimetableDate).getTime() / 1000)
		if(file !== undefined){
			if(file.type === 'image/png' || file.type === 'image/jpeg' ){
				this.setState({uploadState: true});		
				var ImageRef = storage.ref().child('admins/' + this.props.observableStore.uid + '/events/' + this.props.observableStore.currEvent.ID + '/timetable_' + day);
				ImageRef.getDownloadURL().then((url) => {
					if(!this.props.observableStore.currEvent.timetable){this.props.observableStore.currEvent.timetable = []}
					this.props.observableStore.currEvent.timetable.unshift({dag: day, img: url});
					this.toggleTimetableModal();
				}).catch(err => {							
					ImageRef.put(file).then(() =>{
						ImageRef.getDownloadURL().then((url) => {
							if(!this.props.observableStore.currEvent.timetable){this.props.observableStore.currEvent.timetable = []}
							this.props.observableStore.currEvent.timetable.unshift({dag: day, img: url});
							this.toggleTimetableModal();
						})
					});
				})
			}
			else{alert('Bestand moet een jpeg of png afbeelding zijn.')}	
		} 
		
	}

	newAantal = () => {
		this.setState({editAantalIndex: 'new', newFixedAmount: ''});
		this.toggleFixedAmountAddModal();
	}
	
	editAantal = (index) => {
		this.setState({editAantalIndex: index, newFixedAmount: this.state.fixedAmountArray[index]});
		this.toggleFixedAmountAddModal();
	}

	delAantal = (ind) => {
		var fixedAmountArray = this.state.fixedAmountArray;
		fixedAmountArray.splice(ind, 1);
		this.setState({fixedAmountArray: fixedAmountArray});
	}

	saveAantal = () => {
		if(this.state.editAantalIndex === 'new'){
			var fixedAmountArray = this.state.fixedAmountArray;
			fixedAmountArray.push(this.state.newFixedAmount);
			this.setState({fixedAmountArray: fixedAmountArray});
		}
		else{this.state.fixedAmountArray[this.state.editAantalIndex] = this.state.newFixedAmount}
		this.toggleFixedAmountAddModal();
	}

	onAantalSortEnd = ({oldIndex, newIndex}) => {
		var fixedAmountArray = this.state.fixedAmountArray;
		fixedAmountArray = arrayMove(fixedAmountArray, oldIndex, newIndex);	
		this.setState({fixedAmountArray: fixedAmountArray});	
	}

	saveAantallenArray = () => {
		this.props.observableStore.currEvent.muntaantal = this.state.fixedAmountArray;
		this.saveEventInfo('settings')
		this.toggleFixedAmountModal();
	}

	togglePaymentMethod = (ind) => {
		if(this.props.observableStore.isAdmin){
			var methodArr = [...this.props.observableStore.currEvent.paymentMethods]
			methodArr[ind].status = !methodArr[ind].status;
			this.props.observableStore.currEvent.paymentMethods = methodArr;
		}
		this.toggleInfoModal('vraag de betaalmethode eerst aan bij jouw account manager');
	}

	onMethodSortEnd = ({oldIndex, newIndex}) => {
		var methodArray = [...this.props.observableStore.currEvent.paymentMethods];
		methodArray = arrayMove(methodArray, oldIndex, newIndex);	
		this.props.observableStore.currEvent.paymentMethods = methodArray;
	}

	addPaymentMethod = () => {
		var methodArr = [...this.props.observableStore.currEvent.paymentMethods]
		methodArr.push(JSON.parse(this.state.newPaymentMethod));
		this.props.observableStore.currEvent.paymentMethods = methodArr;
		this.toggleAddPaymentMethodModal();
	}

	refundAll = () => {
		if(this.refundProces === 0 && this.props.observableStore.currEvent.pay.serviceId){
			this.refundProces = 1;
			this.setState({refundAllModal: false},()=>{this.refundProces = 0});
			var retourVerzoek = functionsEU.httpsCallable('refundAll');
			retourVerzoek({serviceId: this.props.observableStore.currEvent.pay.serviceId, eid: this.props.observableStore.currEvent.ID, oid: this.props.observableStore.currEvent.oid, muntprijs: this.props.observableStore.currEvent.muntprijs});
		}
	}
	refundProces = 0;

	addApiKey = () => {
		const apiKey = uuidv4();
		if(!this.props.observableStore.currEvent.apiKeys){this.props.observableStore.currEvent.apiKeys = []}
		this.props.observableStore.currEvent.apiKeys.unshift({key: apiKey, note: this.state.newApiNote});
		this.saveEventInfo();
		this.toggleApiModal();
	}

	delApiKey(ind){
		if(this.props.observableStore.currEvent.apiKeys){this.props.observableStore.currEvent.apiKeys.splice(ind, 1)}
		this.saveEventInfo();
	}


	onLinkSortEnd = ({oldIndex, newIndex}) => {
		var methodArray = [...this.props.observableStore.currEvent.weblinks];
		methodArray = arrayMove(methodArray, oldIndex, newIndex);	
		this.props.observableStore.currEvent.weblinks = methodArray;
	}

	addWeblink = () => {
		if(this.state.editWeblinkIndex === -1){
			if(!this.props.observableStore.currEvent.weblinks){this.props.observableStore.currEvent.weblinks = []}
			this.props.observableStore.currEvent.weblinks.push({text: this.state.newWeblinkText, url: this.state.newWeblinkUrl});
			this.toggleWeblinkModal();
		}
		else{
			this.props.observableStore.currEvent.weblinks[this.state.editWeblinkIndex].text = this.state.newWeblinkText;
			this.props.observableStore.currEvent.weblinks[this.state.editWeblinkIndex].url = this.state.newWeblinkUrl;
			this.toggleWeblinkModal();
		}
	}

	delWeblink = (ind) => {
		if(this.props.observableStore.currEvent.weblinks){this.props.observableStore.currEvent.weblinks.splice(ind, 1)}
		this.saveEventInfo();
	}

}

export default Instellingen;




const SortableAmountList = SortableContainer(({items, editAantal, delAantal}) => {
	return (
		<Table className="v-middle" style={{marginBottom: 0, borderWidth: 0}}>
			<tbody>
				{items.map((value, index) => (<SortableAmount key={index} index={index} ind={index} value={value} editAantal={editAantal} delAantal={delAantal} />))}
			</tbody>
		</Table>
	);
});

const SortableAmount = SortableElement(({value, ind, editAantal, delAantal}) => 
	<div className="menuoptienav" style={{background: ind%2 === 0 ? null : 'rgba(0,0,0,0.03)'}} >
		<div >
			<span>{value}</span>
		</div>
		<div style={{marginLeft: 10, display: 'flex', cursor:'pointer'}}>
			<i class="fa fa-edit hightlightIcon2" onClick={() => editAantal(ind)}></i>
			<i class="fa fa-trash hightlightIcon2" style={{marginLeft: 12}} onClick={() => delAantal(ind)}></i>
			<DragHandle />
		</div>
	</div>
);


const SortableMethodList = SortableContainer(({items, togglePaymentMethod, toggleMethodInfoModal}) => {
	return (
		<Table className="v-middle" style={{marginBottom: 0, borderWidth: 0, maxWidth: 380}}>
			<tbody>
				{items.map((value, index) => (<SortableMethod key={index} ind={index} index={index} value={value} togglePaymentMethod={togglePaymentMethod} toggleMethodInfoModal={toggleMethodInfoModal} />))}
			</tbody>
		</Table>
	);
});

const SortableMethod = SortableElement(({value, ind, togglePaymentMethod, toggleMethodInfoModal}) => 
	<div className="switchBtn">
		<div style={{display: 'flex', alignItems: 'center'}}>
			<DragHandle2 />
			<label style={{marginLeft: 25}} htmlFor="staticEmail" className="col-form-label">{value.name}<i class="fa-light fa-circle-info" style={{cursor: 'pointer', color: 'rgba(0,0,0,0.5)', marginLeft: 8, fontSize: 11}}  onClick={()=> toggleMethodInfoModal(ind)}></i></label>
		</div>
		<div style={{cursor: 'pointer', height: 29, width: 55, padding: 3, backgroundColor: value.status ? '#d12a5e' : '#ccc', borderRadius: 25}} onClick={() => togglePaymentMethod(ind)}>
			<div style={{height: 23, width: 23, borderRadius: 12, backgroundColor: '#fff', float: value.status ? 'right' : 'left'}}></div>
		</div>
	</div>	
);

const SortableLinkList = SortableContainer(({items, editLink, delLink}) => {
	return (
		<Table className="v-middle" style={{marginBottom: 0, borderWidth: 0, maxWidth: 380}}>
			<tbody>
				{items.map((value, index) => (<SortableLink key={index} ind={index} index={index} value={value} editLink={editLink} delLink={delLink} />))}
			</tbody>
		</Table>
	);
});

const SortableLink = SortableElement(({value, ind, editLink, delLink}) => 
	<div className="switchBtn">
		<div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
			<div>
				<DragHandle2 />
				<label style={{marginLeft: 25}} htmlFor="staticEmail" className="col-form-label">{value.text}</label>
			</div>
			<div>
				<i class="fa fa-edit hightlightIcon2" onClick={() => editLink(ind)} style={{cursor:'pointer'}}></i>
				<i class="fa fa-trash hightlightIcon2" onClick={() => delLink(ind)} style={{cursor:'pointer', marginLeft: 15}}></i>
			</div>
		</div>
	</div>	
);

const DragHandle = sortableHandle(() => <i class="fa-regular fa-arrows-alt hightlightIcon2" style={{marginLeft: '15%', marginRight: '10%', cursor:'pointer'}}></i>);
const DragHandle2 = sortableHandle(() => <i class="fa-regular fa-arrows-alt hightlightIcon2" style={{cursor:'pointer', fontSize: 13}}></i>);
